import { Freezer, Shelf, Rack, RackDataForBackend } from "@common/types";
import {
	ButtonV2,
	ClickToEdit,
	DropDown,
	DropdownV2,
	GenemodIcon,
	InputV2,
	Modal,
	Notification,
	RadioButtonV2,
	Select,
	SelectV2,
	Typography,
} from "@components";
import DeleteConfirmModal from "@containers/Freezer/components/DeleteConfirmModal/DeleteConfirmModal";
import DeleteModal from "@containers/Freezer/components/DeleteModal";
import { useExportState } from "@containers/Freezer/hooks";
import { truncArgs } from "@helpers/Formatters";
import { useSearchNumericAndSet } from "@helpers/URLParams";

import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import classNames from "classnames";
import commonStyles from "common/styles/common.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import {
	FREEZER_NAME_CHAR_LIMITS,
	MAX_COLS_RACK,
	MAX_ROWS_RACK,
	MAX_SHELVES,
	MIN_COLS,
	MIN_ROWS,
	MIN_SHELVES,
} from "../../data";
import { useFreezer, ShelfCardSkeletons } from "../Contents";
import RackCard from "./RackCard";
import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import "./ShelfRow.scss";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { Category, CategoryDataForBackend } from "@common/types/Category";
import {
	useCategoriesListQuery,
	useCreateCategoryMutation,
} from "@redux/inventory/Category";
import {
	useFreezerDeleteShelfMutation,
	useFreezerInsertRowMutation,
} from "@redux/inventory/Freezer";
import {
	useRacksListQuery,
	useCreateRackMutation,
	useLazyGetRecentRackDimensionsQuery,
} from "@redux/inventory/Rack";
import {
	useShelvesQuery,
	useShelfDeleteMutation,
	useShelfPatchMutation,
	useCreateShelfMutation,
	useShelfBulkUpdateMutation,
} from "@redux/inventory/Shelf";
import { ShelfSelect } from ".";
import styles from "./ShelfRow.module.scss";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";
import { UNARCHIVE_STEPS } from "@containers/Freezer/Repository/components/Unarchive/unarchiveSteps";
import { useHistory } from "react-router-dom";
import InputV3 from "@common/components/InputV3/InputV3";

export type RackCategoryHover = { obj: Rack | Category; toLeft: boolean };

type ShelfRowProps = {
	index: number;
	isChangingDimension: boolean;
	isEditingFreezer: boolean;
	onClickEmptyShelf: (index: number) => void;
	selectRackOrCategory: (
		rack: Rack | Category,
		select?: boolean,
		callback?: () => void
	) => void;
	setLoading: (isLoading: boolean) => void;
	shelf: Shelf | null;
	showEditShelfModal: boolean;
	freezer?: Freezer;
};

export default function ShelfRow(props: ShelfRowProps): JSX.Element {
	const ItemTypes = {
		RACK: "rack",
	};
	const { freezer } = useFreezer(props.freezer?.id);
	const viewOnly = freezer ? freezer.is_archived : true;
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("shelfSettings");
	const [selectedRack] = useSearchNumericAndSet("selected_rack");
	const history = useHistory();

	const { data: currentRowRacks = [], isLoading } = useRacksListQuery({
		location__freezer: freezer?.id,
		is_archived: freezer?.is_archived as boolean,
	} as any);

	const { data: currentRowCategories = [], isLoading: isLoadingCategories } =
		useCategoriesListQuery({
			location__freezer: freezer?.id as number,
			is_archived: freezer?.is_archived as boolean,
		});

	const { data: currentFreezerShelves = [] } = useShelvesQuery(
		{
			id: freezer?.id as number,
			is_archive: freezer?.is_archived,
		},
		{ skip: !freezer?.id }
	);

	const { handleExport, exportModal } = useExportState();
	const [deleteFreezerShelf] = useFreezerDeleteShelfMutation();
	const [deleteSpecificShelf] = useShelfDeleteMutation();
	const [insertFreezerRow] = useFreezerInsertRowMutation();
	const [addingShelf, setAddingShelf] = useState(false);
	const [createShelf] = useCreateShelfMutation();
	const {
		setSelectedLayer,
		setIsArchiveModalVisible,
		setSelectedItems,
		openUnArchiveContainer,
	} = useRepository();

	// Editing and deleting shelves
	const [showShelfOptions, setShowShelfOptions] = useState<boolean>(false);
	const handleShelfOptionsSelection = ({ key }: ClickParam) => {
		if (!props.shelf) return;
		setShowShelfOptions(false);
		if (key === "edit") {
			openPanel({ id: props.shelf.id });
		} else if (key === "export") {
			handleExport("shelves", props.shelf.id, props.shelf.name);
		} else if (key === "restore-rack") {
			openUnArchiveContainer(props.shelf, "RACK");
		} else if (key === "restore-category") {
			openUnArchiveContainer(props.shelf, "CATEGORY");
		} else if (key === "delete") {
			setDeleteShelf(true);
		} else if (key === "archive") {
			setSelectedItems([props.shelf]);
			setSelectedLayer("SHELF");
			setIsArchiveModalVisible(true);
			const params = new URLSearchParams(location.search);
			params.delete("selected_rack");
			params.delete("selected_category");
			history.push({
				pathname: location.pathname,
				search: "?" + params.toString(),
			});
		} else {
			setDeleteShelf(true);
		}
	};

	const [showDeleteShelf, setDeleteShelf] = useState<boolean>(false);

	const handleDeleteShelf = () => {
		const shelf = props.shelf;
		const failure = (err?: string) => {
			console.log("Failed to delete the shelf", err, shelf);
			Notification.warning({
				message: "Failed to delete the shelf",
			});
		};
		if (!shelf) {
			failure();
			return;
		}

		deleteSpecificShelf(shelf.id)
			.unwrap()
			.then(() => {
				setDeleteShelf(false);
				if (selectedRack) {
					props.selectRackOrCategory(
						{ id: selectedRack } as Rack,
						false
					);
				}
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${shelf?.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
			})
			.catch(() => {
				failure();
			});
	};

	/** Dropdown menu for deleting or editing shelves */
	const Item = Menu.Item;
	const shelfOptionsMenu = (
		<Menu onClick={handleShelfOptionsSelection}>
			<DropdownMenuItem
				key="edit"
				label="Settings"
				icon="settings"
				dataCy="shelf-menu-settings"
			/>
			<DropdownMenuItem
				key="export"
				label="Export"
				icon="export"
				dataCy="shelf-menu-export"
			/>
			{!viewOnly && (
				<DropdownMenuItem
					key="restore-rack"
					label="Restore rack"
					icon="undo"
					dataCy="shelf-menu-restore-rack"
				/>
			)}
			<DropdownMenuDivider />
			{!viewOnly && (
				<DropdownMenuItem
					key="restore-category"
					label="Restore category"
					icon="undo"
					dataCy="shelf-menu-restore-category"
				/>
			)}
			{!viewOnly && (
				<DropdownMenuItem
					key="delete"
					label="Delete"
					icon="trash"
					dataCy="shelf-menu-delete"
				/>
			)}
		</Menu>
	);

	/** Controls the expansion of shelves */
	const [isExpanded, setExpanded] = useState<boolean>(true);
	const shelfRef = useRef<HTMLDivElement>(null);

	const racksAndCategories = [
		...currentRowRacks.filter((r) => r.location.shelf === props.shelf?.id),
		...currentRowCategories.filter(
			(c) => c.location.shelf === props.shelf?.id
		),
	];

	let renderedRacks = isExpanded ? racksAndCategories : [];

	/** Only display at most 4 racks/category on Change dimension modal */
	renderedRacks = props.isChangingDimension
		? racksAndCategories.slice(0, 4)
		: racksAndCategories;
	/** Calculate the remaining racks/categories for each shelf */
	const remainingRackNumber =
		racksAndCategories.length - renderedRacks.length;
	renderedRacks.sort(
		(a: Rack | Category, b: Rack | Category) =>
			a.location.index - b.location.index
	);

	/** Drag and drop racks */
	const [rackHover, setRackHover] = useState<RackCategoryHover | null>(null);
	const [{ isOver }, drop] = useDrop({
		accept: ItemTypes.RACK,
		collect(monitor) {
			return {
				isOver: monitor.isOver(),
			};
		},
		hover(item: any) {
			if (props.shelf && item.location_shelf !== props.shelf.id) {
				item.location_shelf = props.shelf.id;
				item.location_index = racksAndCategories.length - 1;
				item.toLeft = false;
			}
		},
		canDrop() {
			return !!props.shelf;
		},
	});

	useEffect(() => {
		if (!props.isEditingFreezer || (rackHover && !isOver)) {
			setRackHover(null);
		}
	}, [isOver, rackHover, props.isEditingFreezer]);
	drop(shelfRef);

	/** Controls which clear/delete modal to display */
	const [clearModal, setClearModal] = useState<JSX.Element | null>(null);
	const clearShelf = (selectedShelf: Shelf | undefined) => {
		const handleClear = () => {
			if (!selectedShelf) return;
			const failure = (err?: string) => {
				console.log("Failed to clear shelf", err);
				Notification.warning({
					message: "Failed to clear shelf",
				});
			};

			deleteSpecificShelf(selectedShelf.id)
				.unwrap()
				.then(() => {
					setRow(-1);
					setClearModal(null);
				})
				.catch(() => {
					failure();
				});
		};

		if (selectedShelf) {
			setClearModal(
				<DeleteModal
					visible={true}
					title="Clear shelf?"
					onCancel={() => setClearModal(null)}
					onConfirm={handleClear}
				/>
			);
		}
	};

	const deleteShelf = (selectedShelfRow: number) => {
		const handleDelete = () => {
			if (!freezer) return;
			const failure = (err?: string) => {
				console.log("Failed to delete shelf", err);
				Notification.warning({
					message: "Failed to delete shelf",
				});
			};

			deleteFreezerShelf({
				freezerID: freezer.id,
				row: selectedShelfRow,
			})
				.unwrap()
				.then(() => {
					setRow(-1);
					setClearModal(null);
				})
				.catch(() => {
					failure();
				});
		};

		setClearModal(
			<DeleteConfirmModal
				title="Delete this shelf?"
				itemName="this shelf"
				onConfirm={handleDelete}
				onCancel={() => setClearModal(null)}
				visible={true}
				type="shelf"
			/>
		);
	};

	const insertShelf = (
		up: boolean,
		selectedShelf: number,
		num_shelves: number
	) => {
		if (!freezer) return;
		const failure = (err?: string) => {
			console.log("Failed to insert shelf", err);
			Notification.warning({
				message: "Failed to insert shelf",
			});
		};

		let newShelf = -1;

		if (!up) {
			newShelf = selectedShelf + 1;
		} else {
			newShelf = selectedShelf;
		}

		insertFreezerRow({
			freezerID: freezer.id,
			row: newShelf,
		})
			.unwrap()
			.then()
			.catch(failure);
	};

	const handleEditShelfMenu = (e: ClickParam) => {
		if (!freezer) return;
		setOpenDropdown(false);
		const event = e.domEvent;
		event.stopPropagation();

		const key = e.key;

		switch (key) {
			case "insert above":
				if (freezer.shelves < MAX_SHELVES) {
					insertShelf(true, row, freezer.shelves + 1);
				} else {
					setClearModal(
						<Modal
							visible
							width={496}
							title="Reached maximum number of shelves"
							onOk={() => setClearModal(null)}
							hideCancelButton
							onCancel={() => setClearModal(null)}
						>
							You reached the maximum number of shelves in a
							freezer.
						</Modal>
					);
				}
				break;
			case "insert below":
				if (freezer.shelves < MAX_SHELVES) {
					insertShelf(false, row, freezer.shelves + 1);
				} else {
					setClearModal(
						<Modal
							visible
							width={496}
							title="Reached maximum number of shelves"
							onOk={() => setClearModal(null)}
							hideCancelButton
							onCancel={() => setClearModal(null)}
						>
							You reached the maximum number of shelves in a
							freezer.
						</Modal>
					);
				}
				break;
			case "delete":
				if (!(freezer.shelves - 1 < MIN_SHELVES)) {
					deleteShelf(row);
				} else {
					setClearModal(
						<Modal
							visible
							width={496}
							title="Must have at least one shelf"
							onOk={() => setClearModal(null)}
							hideCancelButton
							onCancel={() => setClearModal(null)}
						>
							You must have at least one shelf in a freezer.
						</Modal>
					);
				}
				break;
			case "clear": {
				const selectedShelf = currentFreezerShelves.find(
					(shelf: Shelf) => shelf.row === row
				);
				clearShelf(selectedShelf);
				break;
			}
			default:
				break;
		}
	};

	const [row, setRow] = useState<number>(-1);

	const editShelfMenu = (
		<Menu selectedKeys={[]} onClick={handleEditShelfMenu}>
			<Item key="insert above" data-cy="edit-shelf-menu-insert-above">
				Insert 1 above
			</Item>
			<Item key="insert below" data-cy="iedit-shelf-menu-nsert-below">
				Insert 1 below
			</Item>
			<Item key="delete" data-cy="edit-shelf-menu-delete">
				Delete shelf
			</Item>
			<Item key="clear" data-cy="edit-shelf-menu-clear">
				Clear shelf
			</Item>
		</Menu>
	);

	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
	const [addRackOrCategory, setAddRackOrCategory] = useState<boolean>(false);
	const [isAddingRack, setIsAddingRack] = useState<boolean>(true);
	const [openDropdown, setOpenDropdown] = useState(false);

	const rackOrCategoryDropdownMenu = () => {
		return (
			<Menu selectedKeys={[]} onClick={handleCreateMenu}>
				<Item key="new rack" data-cy="shelf-plus-dropdown-rack">
					Rack
				</Item>
				<Item key="new category" data-cy="shelf-plus-dropdown-category">
					Category
				</Item>
			</Menu>
		);
	};

	const handleCreateMenu = (e: ClickParam) => {
		const event = e.domEvent;
		event.cancelBubble = true;
		event.stopPropagation();
		setDropdownVisible(false);

		const key = e.key;
		if (key === "new rack") {
			setIsAddingRack(true);
		} else if (key === "new category") {
			setIsAddingRack(false);
		}
		setAddRackOrCategory(true);
	};

	const [addRack] = useCreateRackMutation();
	const [addCategory] = useCreateCategoryMutation();
	const handleAddRackOrCategory = (
		obj: RackDataForBackend | CategoryDataForBackend
	) => {
		const isCategory = !isAddingRack;
		if (!props.shelf) return;
		if (isCategory) {
			addCategory({
				categoryData: obj as CategoryDataForBackend,
			})
				.unwrap()
				.then((category) => {
					SegmentTrackEvent(SegmentFreezerEvents.RACK_CREATE, {
						id: category.id,
						is_category: true,
					});
					setAddRackOrCategory(false);
					props.selectRackOrCategory(category);
				})
				.catch(() =>
					Notification.warning({
						message: `Failed to create a category.`,
					})
				);
			return;
		} else {
			addRack(obj as RackDataForBackend)
				.unwrap()
				.then((rack) => {
					SegmentTrackEvent(SegmentFreezerEvents.RACK_CREATE, {
						id: rack.id,
						num_rows: rack.rows,
						num_cols: rack.columns,
						is_category: false,
					});
					setAddRackOrCategory(false);
					props.selectRackOrCategory(rack);
				})
				.catch(() =>
					Notification.warning({
						message: `Failed to create a ${
							isCategory ? "category" : "rack"
						}.`,
					})
				);
		}
	};

	const shelfContentRef = useRef<HTMLDivElement>(null);

	const [shelfTop, setShelfTop] = useState(0);
	useEffect(() => {
		const curr = shelfRef.current;
		if (curr && curr.offsetTop !== shelfTop) {
			setShelfTop(curr.offsetTop);
		}
	}, [shelfRef.current]);

	const onAddShelf = () => {
		if (!props.isEditingFreezer) props.onClickEmptyShelf(props.index);
	};

	const [updateShelf] = useShelfPatchMutation();
	const handleShelfRename = (newName: string) => {
		if (!props.shelf) return;
		updateShelf({
			...props.shelf,
			name: newName.trim(),
		})
			.unwrap()
			.then((shelf) =>
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${shelf.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				})
			)
			.catch((err) => console.log(err));
	};

	const handleShelfRowClick = () => {
		if (!props.shelf && !addingShelf) {
			setAddingShelf(true);
		}
	};

	const renderName = (shelf: Shelf, untitled?: boolean) => {
		if (!props.showEditShelfModal) {
			return (
				<ClickToEdit
					value={shelf.name || ""}
					onComplete={(e) => {
						handleShelfRename(e);
					}}
					component="input"
					className={styles.clickToEdit}
					maxLength={FREEZER_NAME_CHAR_LIMITS.SHELF}
					readOnly={viewOnly}
					dataCy={`shelf-${props.index}-name`}
					color="text-secondary-v2"
					addConfirmAndCancel={false}
					cancelEditionIfEmpty
					tabToSave
				/>
			);
		}
		{
			return (
				<Typography
					className={styles.clickToEdit}
					color="text-secondary-v2"
				>
					{shelf.name}
				</Typography>
			);
		}
	};

	const renderUntitled = () => {
		if (!props.showEditShelfModal) {
			return (
				<ClickToEdit
					readOnly={freezer?.is_archived}
					edit={addingShelf}
					className={classNames(styles.clickToEdit)}
					placeholder="Untitled"
					noHoverEffect
					value={""}
					onCancel={() => {
						setAddingShelf(false);
					}}
					onComplete={(name) => {
						if (!freezer) return;
						createShelf({
							name,
							freezer: freezer.id,
							row: props.index,
							description: "",
						});
					}}
					component="input"
					addConfirmAndCancel={false}
					lightBackground
				/>
			);
		} else {
			return (
				<Typography
					className={styles.clickToEdit}
					color="text-tertiary-v2"
				>
					Untitled
				</Typography>
			);
		}
	};

	const shelfNameSection = () => {
		return (
			<div className={styles.toolbar}>
				{props.shelf && renderName(props.shelf)}
				{!props.shelf && renderUntitled()}
				{/* Plus icon only shows when there's a shelf created & is not on edit*/}
				{!props.isEditingFreezer && props.shelf && !viewOnly && (
					<DropdownV2
						overlay={rackOrCategoryDropdownMenu}
						visible={dropdownVisible}
						onVisibleChange={(v) => setDropdownVisible(v)}
						getPopupContainer={(trigger) =>
							trigger.parentNode as HTMLElement
						}
					>
						<ButtonV2
							size="small"
							icon="plus"
							style={{ marginLeft: "auto" }}
							iconColor="text-secondary-v2"
							className={styles.addStorageBtn}
						>
							<Typography
								variant="small"
								color="text-secondary-v2"
							>
								Add storage
							</Typography>
						</ButtonV2>
					</DropdownV2>
				)}
				{!props.isEditingFreezer && props.shelf && (
					<DropdownV2
						visible={showShelfOptions}
						onVisibleChange={(v) => setShowShelfOptions(v)}
						overlay={shelfOptionsMenu}
						getPopupContainer={(trigger) =>
							trigger.parentNode as HTMLElement
						}
					>
						<div className={styles.options}>
							<GenemodIcon
								className={styles.optionsIcon}
								name="meatballs"
								dataCy={
									props?.shelf?.name
										.toLowerCase()
										.replace(/\s/g, "-") + "-menu"
								}
							/>
						</div>
					</DropdownV2>
				)}
			</div>
		);
	};

	return (
		<>
			<div
				ref={shelfRef}
				className={classNames(styles.shelf, {
					[styles.clickable]: !props.shelf,
					[styles.editable]: props.showEditShelfModal,
				})}
				onClick={handleShelfRowClick}
			>
				{props.showEditShelfModal && (
					<div
						className="row-header"
						onClick={() => setRow(props.index)}
					>
						<Typography variant="label">
							{props.index + 1}
						</Typography>
						<DropdownV2
							overlay={editShelfMenu}
							open={openDropdown}
							onOpenChange={(open) => setOpenDropdown(open)}
						>
							<GenemodIcon
								name="caret-down"
								className="row-options"
								dataCy={`shelf-${props.index}-caret`}
								fill="text-secondary"
							/>
						</DropdownV2>
					</div>
				)}
				{shelfNameSection()}
				{renderedRacks.length ? (
					<div className={styles.grid}>
						{renderedRacks.map((r: Rack | Category) => {
							return (
								<RackCard
									viewOnly={viewOnly}
									key={r.id}
									rackOrCategory={r}
									selectRackOrCategory={
										props.selectRackOrCategory
									}
									rackHover={rackHover}
									setRackHover={setRackHover}
									isEditingFreezer={props.isEditingFreezer}
									setLoading={props.setLoading}
									style={{
										width: "calc(calc(100% / 4) - 9px)",
									}}
									dataCy={`rack-card-${r.name.replace(
										/\s/g,
										""
									)}`}
								/>
							);
						})}
					</div>
				) : (
					<div className={styles.rowEmpty}>
						{props.shelf || props.showEditShelfModal ? (
							<Typography variant="small">No storage</Typography>
						) : (
							<Typography variant="small" color="text-tertiary">
								{freezer?.is_archived
									? "No location"
									: "Click to add shelf name"}
							</Typography>
						)}
					</div>
				)}
				{props.isChangingDimension && (
					<div className="remaining-rack-number">
						{remainingRackNumber !== 0 && (
							<Typography variant="caption">
								+ {remainingRackNumber}
							</Typography>
						)}
					</div>
				)}
				{clearModal}
				{exportModal}
			</div>
			<DeleteConfirmModal
				title="Delete this shelf?"
				itemName={props?.shelf?.name || ""}
				onConfirm={handleDeleteShelf}
				onCancel={() => setDeleteShelf(false)}
				visible={showDeleteShelf}
				type="shelf"
			/>
			<CreateRackOrCategoryModal
				shelf={props.shelf}
				racksAndCategories={racksAndCategories}
				visible={addRackOrCategory}
				onCancel={() => setAddRackOrCategory(false)}
				isAddingRack={isAddingRack}
				setIsAddingRack={setIsAddingRack}
				onConfirm={handleAddRackOrCategory}
				dataCy="rack-category-modal"
			/>
		</>
	);
}

type CreateRackOrCategoryModalProps = {
	visible: boolean;
	onCancel: () => void;
	isAddingRack: boolean;
	setIsAddingRack: React.Dispatch<React.SetStateAction<boolean>>;
	onConfirm: (arg: RackDataForBackend | CategoryDataForBackend) => void;
	shelf?: Shelf | null;
	racksAndCategories?: Array<Rack | Category>;
	dataCy: string;
	isRepository?: boolean;
};
export function CreateRackOrCategoryModal(
	props: CreateRackOrCategoryModalProps
) {
	const Option = Select.Option;
	const { freezer } = useFreezer();
	const [shelf, setShelf] = useState<Shelf | null>(null);
	const [getRecentRackDimensions] = useLazyGetRecentRackDimensionsQuery();

	useEffect(() => {
		setTempName("");
		setRows(3);
		setCols(3);
		setIsCustom(false);
		setRecent(false);
		setLoading(false);
		setShowErrors(false);
		setShelf(null);
		if (props.visible && freezer) {
			getRecentRackDimensions(freezer.id)
				.unwrap()
				.then((res) => {
					if (res && res.row && res.col) {
						setRecent(true);
						setRecentDimension({
							rows: res.row,
							cols: res.col,
							recent: `${res.row}x${res.col}`,
						});
						setRows(res.row);
						setCols(res.col);
					}
				});
		}
	}, [props.visible]);

	const [tempName, setTempName] = useState<string>("");
	const [showErrors, setShowErrors] = useState(false);
	const [rows, setRows] = useState<number>(3);
	const [cols, setCols] = useState<number>(3);
	const [isCustom, setIsCustom] = useState<boolean>(false);
	const [isRecent, setRecent] = useState<boolean>(false);
	const [recentDimension, setRecentDimension] = useState<{
		rows: number | null;
		cols: number | null;
		recent: string | null;
	}>({
		rows: null,
		cols: null,
		recent: null,
	});
	const handleRadioChange = (e: string) => {
		if (e === "CUSTOM") {
			setIsCustom(true);
			setRecent(false);
		} else if (e === "RECENT") {
			setRows(recentDimension.rows || 3);
			setCols(recentDimension.cols || 3);
			setRecent(true);
			setIsCustom(false);
		} else {
			if (e === "3x3") {
				setRows(3);
				setCols(3);
			} else if (e === "4x3") {
				setRows(4);
				setCols(3);
			} else if (e === "4x4") {
				setRows(4);
				setCols(4);
			}
			setRecent(false);
			setIsCustom(false);
		}
	};

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const getRackDataLocation = () => {
		if (
			!props.isRepository &&
			props.racksAndCategories !== undefined &&
			props.shelf !== undefined
		) {
			return {
				location: {
					index:
						Math.max(
							...props.racksAndCategories.map(
								(r) => r.location.index
							),
							-1
						) + 1,
					shelf: props.shelf?.id || -1,
				},
			};
		}

		if (shelf) {
			return {
				location: {
					index:
						(shelf.rack_numbers || 0) +
						(shelf.category_numbers || 0),
					shelf: shelf.id,
				},
			};
		}

		return undefined;
	};

	const getCategoryDataLocation = () => {
		if (
			!props.isRepository &&
			props.racksAndCategories !== undefined &&
			props.shelf !== undefined
		) {
			return {
				location: {
					index:
						Math.max(
							...props.racksAndCategories.map(
								(r) => r.location.index
							),
							-1
						) + 1,
					shelf: props.shelf?.id || -1,
				},
			};
		}
		if (shelf) {
			return {
				location: {
					index:
						(shelf.rack_numbers || 0) +
						(shelf.category_numbers || 0),
					shelf: shelf.id,
				},
			};
		}

		return undefined;
	};

	const [isLoading, setLoading] = useState(false);
	const handleSubmit = () => {
		if (!validateForm()) {
			setShowErrors(true);
			return;
		}
		setLoading(true);
		if (props.isAddingRack) {
			const newRackData = {
				name: tempName,
				rows: props.isAddingRack ? rows : 0,
				columns: props.isAddingRack ? cols : 0,
				...getRackDataLocation(),
			} as RackDataForBackend;
			props.onConfirm(newRackData);
			setShelf(null);
		} else {
			const newCategoryData = {
				name: tempName,
				...getCategoryDataLocation(),
			} as CategoryDataForBackend;
			props.onConfirm(newCategoryData);
		}
	};

	const dimensionOptions = [
		{
			value: "3x3",
			row: 3,
			col: 3,
		},
		{
			value: "4x3",
			row: 4,
			col: 3,
		},
		{
			value: "4x4",
			row: 4,
			col: 4,
		},
	];

	const hasRecentDimension = () => {
		if (!recentDimension.recent) return false;

		const alredyExist = dimensionOptions.find(
			(o) => o.value === recentDimension.recent
		);

		if (alredyExist) return false;

		return true;
	};

	return (
		<Modal
			visible={props.visible}
			destroyOnClose
			closable={true}
			title={props.isAddingRack ? "New rack" : "New category"}
			okText={props.isAddingRack ? "Create rack" : "Create category"}
			onOk={handleSubmit}
			hideCancelButton
			onCancel={props.onCancel}
			okButtonProps={{
				disabled: !validateForm(),
				loading: isLoading,
				style: {
					pointerEvents: "visible",
				},
			}}
			width={400}
			dataCy={props.dataCy}
		>
			<InputV3
				value={tempName}
				label={props.isAddingRack ? "Rack name *" : "Category name *"}
				labelColor="text-primary-v2"
				placeholder={
					props.isAddingRack ? "e.g. rack #1" : "e.g. common reagents"
				}
				onChange={(e) => setTempName(e.target.value)}
				onPressEnter={handleSubmit}
				autoFocus
				errorMessage={!tempName && showErrors ? "Required" : undefined}
				className={styles.inputName}
				dataCy={`${props.dataCy}-name`}
			/>

			{props.isAddingRack && (
				<>
					<Typography
						variant="label"
						color="text-primary-v2"
						style={{ marginBottom: 8, fontWeight: 500 }}
					>
						Dimension (h x w)
					</Typography>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 12,
						}}
					>
						{recentDimension && hasRecentDimension() && (
							<RadioButtonV2
								className={commonStyles.flexCenter}
								checked={isRecent}
								name={`recent`}
								onChange={handleRadioChange}
								value={"RECENT"}
								dataCy={`${props.dataCy}-recent`}
								labelColor="text-secondary-v2"
								radioLabel={
									<Typography
										variant="subheadline"
										color="text-secondary-v2"
									>
										{`${recentDimension.rows} x ${recentDimension.cols} (recently used)`}
									</Typography>
								}
							></RadioButtonV2>
						)}
						{dimensionOptions.map((option) => (
							<RadioButtonV2
								className={commonStyles.flexCenter}
								key={option.value}
								name={option.value}
								size="large"
								checked={
									!isCustom &&
									rows === option.row &&
									cols === option.col
								}
								onChange={handleRadioChange}
								value={option.value}
								dataCy={`${props.dataCy}-${option.value}`}
								radioLabel={
									<Typography
										variant="subheadline"
										color="text-secondary-v2"
									>
										{option.row} x {option.col}
										{recentDimension.recent ===
											option.value && " (recently used)"}
									</Typography>
								}
							/>
						))}
						<RadioButtonV2
							name="custom"
							checked={isCustom}
							onChange={handleRadioChange}
							className={commonStyles.flexCenter}
							value={"CUSTOM"}
							dataCy={`${props.dataCy}-custom`}
							radioLabel={
								<div className={commonStyles.flexCenter}>
									<Typography
										variant="subheadline"
										color="text-secondary-v2"
										style={{ marginRight: 16 }}
									>
										Custom
									</Typography>
								</div>
							}
						/>
						<div>
							{isCustom && (
								<div className={styles.customSize}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Typography
											variant="label"
											color="text-secondary-v2"
											style={{
												marginRight: 4,
											}}
										>
											Height:
										</Typography>
										<SelectV2
											defaultValue={3}
											defaultActiveFirstOption
											value={rows}
											onSelect={(option) =>
												setRows(
													parseInt(option as string)
												)
											}
											style={{
												width: 68,
												marginRight: 8,
											}}
										>
											{Array(MAX_ROWS_RACK - MIN_ROWS + 1)
												.fill(
													MAX_ROWS_RACK - MIN_ROWS + 1
												)
												.map((_, index) => {
													return (
														<Option
															key={
																MIN_ROWS + index
															}
														>
															{MIN_ROWS + index}
														</Option>
													);
												})}
										</SelectV2>
									</div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Typography
											variant="label"
											color="text-secondary-v2"
											style={{
												marginRight: 4,
											}}
										>
											Width:
										</Typography>
										<SelectV2
											defaultValue={3}
											defaultActiveFirstOption
											value={cols}
											onSelect={(option) =>
												setCols(
													parseInt(option as string)
												)
											}
											style={{
												width: 68,
												marginLeft: 8,
											}}
										>
											{Array(MAX_COLS_RACK - MIN_COLS + 1)
												.fill(
													MAX_ROWS_RACK - MIN_ROWS + 1
												)
												.map((_, index) => {
													return (
														<Option
															key={
																MIN_COLS + index
															}
														>
															{MIN_COLS + index}
														</Option>
													);
												})}
										</SelectV2>
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			)}
			{props.isRepository && (
				<ShelfSelect shelf={shelf} setShelf={setShelf} />
			)}
		</Modal>
	);
}
