import React, { HTMLProps, useEffect, useState } from "react";
import cn from "classnames";
import {
	ButtonV2,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	IconName,
	Typography,
	UpgradeButton,
} from "@components";
import { Semaphore } from "@helpers/Hooks";
import styles from "./RestrictionWarning.module.scss";
import ExploreButton from "../ExploreButton/ExploreButton";

export function RESTRICTIONWARNING_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Restriction warning section/banner"
					description="Restriction warning section/banner"
				>
					<div style={{ width: 504 }}>
						<RestrictionWarning>
							<Typography>
								To create a shared freezer, you must invite a
								colleague first.
							</Typography>
						</RestrictionWarning>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

type RestrictionWarningProps = {
	/** trigger to animate */
	animationSemaphore?: Semaphore;
	showUpgradeButton?: boolean;
	showExplorePlansButton?: boolean;
	children: React.ReactNode;
	icon?: IconName;
	showActions?: boolean;
	okButtonText?: string;
	cancelButtonText?: string;
	onOk?: () => void;
	onCancel?: () => void;
} & HTMLProps<HTMLDivElement>;

const ANIMATION_DURATION = 500;

/**
 * Banner displayed to warn about restrictions
 */
export default function RestrictionWarning({
	animationSemaphore,
	showUpgradeButton = false,
	showExplorePlansButton = false,
	children,
	icon = "error",
	showActions,
	okButtonText = "Ok",
	cancelButtonText = "Cancel",
	className,
	onOk,
	onCancel,
	...props
}: RestrictionWarningProps): JSX.Element {
	const [firstAnimationSkipped, setFirstAnimationSkipped] = useState(false);
	const [animateActive, setAnimateActive] = useState(false);

	useEffect(() => {
		if (!firstAnimationSkipped) {
			setFirstAnimationSkipped(true);
			return;
		}

		setAnimateActive(true);
		setTimeout(() => setAnimateActive(false), ANIMATION_DURATION);
	}, [animationSemaphore]);

	return (
		<div
			className={cn(
				styles.restrictionWarning,
				{
					[styles.restrictionWarning__active]: animateActive,
				},
				className
			)}
			{...props}
		>
			<div className={styles.text}>
				<div className={styles.iconContainer}>
					<GenemodIcon name={icon} color="warning" size="large" />
				</div>
				{children}
			</div>
			<div>
				{showActions && (
					<div className={styles.actions}>
						<Typography
							color="link-primary"
							style={{ cursor: "pointer" }}
							onClick={onOk}
						>
							{okButtonText}
						</Typography>
						<Typography
							color="danger"
							style={{ cursor: "pointer" }}
							onClick={onCancel}
						>
							{cancelButtonText}
						</Typography>
					</div>
				)}
				{showUpgradeButton && (
					<div style={{ marginTop: 16 }}>
						<UpgradeButton
							style={{
								width: 180,
								justifyContent: "center",
							}}
						/>
					</div>
				)}
				{showExplorePlansButton && (
					<ExploreButton style={{ marginTop: 16, width: 180 }} />
				)}
			</div>
		</div>
	);
}
