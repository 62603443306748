import { ProductMenuItemProps } from "@common/components/ProductMenu";
import { ExperimentMaterialTemplate, PlanName } from "@common/types";
import { FRAMEWORK_PATHS } from "@root/routes";
import { title } from "process";

export const FRAMEWORK_MENU_ITEMS: readonly ProductMenuItemProps[] = [
	{
		title: "Properties",
	},
	{
		title: "Units",
		submenu: true,
	},
	{
		title: "Materials",
		submenu: true,
	},
	{
		title: "Notebook",
	},
	{
		title: "Material templates",
		route: FRAMEWORK_PATHS.MATERIAL_TEMPLATES,
		submenu: true,
	},
] as const;

export const DEFAULT_MATERIAL_TEMPALTE_LAYOUT = {
	name: "Name",
	quantity: "Quantity",
	price: "Price",
	link_type: "Link type",
	link: "Link",
	source: "Source",
	reference: "Reference",
	catalog: "Catalog #",
	lot: "Lot #",
	packaging: "Packaging",
	origin: "Origin",
} as const;

export const isDefaultTemplate = (
	template: ExperimentMaterialTemplate | undefined
) => {
	if (!template) return true;
	return template?.name === "Default" && template?.predefined;
};
