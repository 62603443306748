import {
	Button,
	ButtonV2,
	GenemodIcon,
	LoadingSpinner,
	Notification,
	Typography,
} from "@common/components";
import { ExperimentId, ExperimentMaterial } from "@common/types";
import { useWindowDimensions } from "@helpers/Hooks";
import {
	useExperimentMaterialsQuery,
	useGetDefaultMaterialTemplateQuery,
	useGetMaterialTemplateQuery,
	useGetMaterialTemplatesQuery,
	useLazyExportExperimentMaterialsQuery,
} from "@redux/ProjectManagement/PmApiSlice";
import { usePmDocumentSlice } from "@redux/ProjectManagement/PmDocumentSlices";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import MaterialsTable from "./MaterialsTable";
import styles from "./index.module.scss";
import SelectMaterialTemplateModal from "./SelectMaterialTemplateModal/SelectMaterialTemplateModal";
import { isDefaultTemplate } from "@containers/Framework/constants";

type ExperimentFileMaterialsProps = {
	isArchived?: boolean;
	experimentFileId?: number;
	hideTitle?: boolean;
	experimentTemplateId: number;
	materialTemplateId?: number;
};

export const MATERIAL_DEFAULT_VALUES = {
	name: "",
	origin: 0,
	found: 0,
	source: "",
	reference: "",
	catalog: "",
	lot: "",
	packaging: "",
	price: "",
	currency: 0,
};

export default function ExperimentFileMaterials({
	isArchived,
	experimentFileId,
	hideTitle,
	experimentTemplateId,
}: ExperimentFileMaterialsProps) {
	const { data: materialsTemplate = [] } = useGetMaterialTemplatesQuery();
	const { data: template } = useGetMaterialTemplateQuery(
		experimentTemplateId || skipToken
	);

	const { data: defaultTemplate } = useGetDefaultMaterialTemplateQuery();
	const [showSelectTemplateModal, setshowSelectTemplateModal] =
		useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [newMaterials, setNewMaterials] = useState<any[]>([]);

	const { height, width } = useWindowDimensions();
	const [exportMaterials] = useLazyExportExperimentMaterialsQuery();
	const addNewMaterialRow = () => {
		if (!experimentFileId) return;
		if (isDefaultTemplate(template)) {
			setNewMaterials([
				...newMaterials,
				{
					id: -Math.random(),
					experiment_file: experimentFileId,
					...MATERIAL_DEFAULT_VALUES,
				},
			]);
		} else if (template) {
			const customTemplate = template.schema.reduce((accum, val) => {
				accum[val.uuid] = "";
				return accum;
			}, {} as any);

			setNewMaterials([
				...newMaterials,
				{
					id: -Math.random(),
					...customTemplate,
				},
			]);
		}
	};

	const {
		data: materialSearchData,
		isFetching,
		isLoading,
	} = useExperimentMaterialsQuery(
		experimentFileId
			? {
					experiment_file: experimentFileId,
					page_size: 99999,
			  }
			: skipToken
	);

	const data = materialSearchData?.results || [];
	const noResults = data.length === 0;

	const onExport = () => {
		if (!experimentFileId) return;
		setIsExporting(true);
		exportMaterials({
			experiment_file: experimentFileId,
			// ...filters,
		})
			.unwrap()
			.then((response) => {
				fileDownload(response, `materials.csv`, "text/csv");
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to export materials.",
				});
			})
			.finally(() => setIsExporting(false));
	};

	const { setRightBarVisibleType } = usePmDocumentSlice(
		"rightBarVisibleType",
		"notesCommentTypeFilter"
	);

	return (
		<div className={styles.container}>
			{hideTitle ? null : (
				<div className={styles.flex}>
					<Typography
						variant="label"
						bold
						style={{ marginRight: "auto" }}
					>
						Materials
					</Typography>
					<GenemodIcon
						name="double-arrow-right"
						onClick={() => setRightBarVisibleType("CLOSED")}
					/>
				</div>
			)}
			<div className={styles.actions}>
				<Typography variant="headline3">Materials</Typography>
				{!isArchived && (
					<ButtonV2
						icon="plus-circle"
						type="primary"
						onClick={() => {
							addNewMaterialRow();
						}}
						style={{ marginLeft: "auto" }}
					>
						Add material
					</ButtonV2>
				)}
				{!isArchived && (
					<ButtonV2
						type="primary"
						onClick={() => {
							setshowSelectTemplateModal(true);
						}}
						style={{ marginLeft: 8 }}
					>
						Change template
					</ButtonV2>
				)}
				{data && data.length > 0 && (
					<ButtonV2
						style={{ marginLeft: 12 }}
						icon="export"
						className={styles.export}
						onClick={onExport}
						loading={isExporting}
						disabled={noResults}
					>
						Export CSV
					</ButtonV2>
				)}
			</div>

			<div className={styles.tableContainer}>
				{isFetching && (
					<div className={styles.loadingOverlay}>
						<LoadingSpinner loading size="large" />
					</div>
				)}
				<MaterialsTable
					materials={data || []}
					newMaterials={newMaterials}
					setNewMaterials={(materias) =>
						setNewMaterials(materias as ExperimentMaterial[])
					}
					isLoading={isLoading}
					scrollHeight={height - 230}
					scrollWidth={width - 200}
					isArchived={isArchived || false}
					hideOriginFilter
					template={template || defaultTemplate}
					experimentFileId={experimentFileId}
				/>
			</div>
			<SelectMaterialTemplateModal
				experimentFileId={experimentFileId as ExperimentId}
				open={showSelectTemplateModal}
				onClose={() => {
					setshowSelectTemplateModal(false);
				}}
				experimentsTemplateId={
					experimentTemplateId ||
					materialsTemplate.find((t) => isDefaultTemplate(t))?.id ||
					0
				}
				materialIds={data.map((d) => d.id)}
			/>
		</div>
	);
}
