import {
	Modal,
	Typography,
	GenemodLink,
	GenemodIcon,
} from "@common/components";
import InputV3 from "@common/components/InputV3/InputV3";
import { ExperimentId } from "@common/types";
import MaterialTemplateCollapsable from "@containers/Framework/components/MaterialTemplateCollapsable";
import { isDefaultTemplate } from "@containers/Framework/constants";
import {
	useExperimentMaterialBulkDeleteMutation,
	useGetMaterialTemplatesQuery,
	usePatchExperimentFileMutation,
} from "@redux/ProjectManagement/PmApiSlice";
import React, { useEffect, useState } from "react";
import styles from "./SelectMaterialsTemplateModal.module.scss";

type Props = {
	open: boolean;
	onClose: () => void;
	experimentsTemplateId: number;
	experimentFileId: ExperimentId;
	materialIds: number[];
};

const SelectMaterialTemplateModal = ({
	open,
	onClose,
	experimentsTemplateId,
	materialIds,
	experimentFileId,
}: Props) => {
	const [selectedTemplate, setselectedTemplate] = useState(
		experimentsTemplateId
	);
	const [search, setSearch] = useState("");
	const { data: materialsTemplate = [] } = useGetMaterialTemplatesQuery({
		search,
	});
	const [patchExperiment] = usePatchExperimentFileMutation();
	const [bulkDeleteMaterials] = useExperimentMaterialBulkDeleteMutation();

	useEffect(() => {
		setselectedTemplate(experimentsTemplateId);
	}, [experimentsTemplateId]);

	return (
		<Modal
			visible={open}
			width={800}
			title="Select materials template"
			okText="Save"
			hideCancelButton
			onCancel={onClose}
			wrapClassName={styles.modal}
			onOk={() => {
				patchExperiment({
					id: experimentFileId,
					materials_template: selectedTemplate,
				});
				bulkDeleteMaterials({ material_ids: materialIds })
					.unwrap()
					.finally(() => {
						onClose();
					});
			}}
			okButtonProps={{
				disabled: experimentsTemplateId === selectedTemplate,
			}}
		>
			<div className={styles.actions}>
				<Typography>
					{materialsTemplate.length} templates found.{" "}
					<GenemodLink to="FRAMEWORK_MATERIAL_TEMPLATES">
						Manage templates.
					</GenemodLink>
				</Typography>
				<InputV3
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					prefix={<GenemodIcon name="search" />}
					placeholder="Search template by name"
					wrapperProps={{
						style: {
							width: 240,
							marginLeft: "auto",
						},
					}}
				/>
			</div>
			<div className={styles.list}>
				{materialsTemplate.map((template) => {
					return (
						<MaterialTemplateCollapsable
							key={template.id}
							{...template}
							showOnly
							checked={selectedTemplate === template.id}
							onRadioChange={(id) => {
								setselectedTemplate(id);
							}}
							experimentTemplate={experimentsTemplateId}
						/>
					);
				})}
			</div>
			{selectedTemplate !== experimentsTemplateId && (
				<Typography color="danger" medium>
					Note: Switching to this template will remove all your
					current data.
				</Typography>
			)}
		</Modal>
	);
};

export default SelectMaterialTemplateModal;
