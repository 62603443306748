import React, { useEffect, useState } from "react";
import { FREEZER_PATHS } from "..";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import cn from "classnames";
import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import {
	ClickToEdit,
	DropDown,
	GenemodIcon,
	Modal,
	Notification,
	Typography,
} from "@components";
import BoxMovingCart from "@containers/Freezer/components/MovingCart/BoxMovingCart";
import RearrangeModal from "@containers/Freezer/components/RearrangeModal/RearrangeModal";
import {
	MAX_COLS_RACK,
	MAX_ROWS_RACK,
	MIN_COLS,
	MIN_ROWS,
} from "@containers/Freezer/data";
import { Box, BoxDataForBackend, Rack } from "@common/types";
import { truncArgs } from "@helpers/Formatters";
import { getRackContentDimensions, useWindowDimensions } from "@helpers/Hooks";
import DeleteModal from "../components/DeleteModal";
import BoxTableCell from "./BoxTableCell";
import { useFreezer, useSelectedRack } from "./Contents";
import "./RackView.scss";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrganizationRouter } from "@root/AppRouter";
import { useHistory } from "react-router-dom";
import {
	useBoxesQuery,
	useBoxCreateMutation,
	useBoxDeleteMutation,
} from "@redux/inventory/Box";
import {
	useInsertRackRowMutation,
	useInsertRackColMutation,
	useDeleteRackRowMutation,
	useDeleteRackColMutation,
	useRackPatchMutation,
} from "@redux/inventory/Rack";
import styles from "./RackView.module.scss";
import { RackMenu } from "./ShelfView/RackCard";
import { MenuProps } from "antdv4";

type RackViewProps = {
	/** whether to show editRackModal or not */
	showEditRackModal: boolean;
	/** whether to show rearrangeBoxModal or not */
	showRearrangeBoxModal: boolean;
	setShowEditRackModal: (visible: boolean) => void;
	setShowRearrangeBoxModal: (visible: boolean) => void;
};

export default function RackView(props: RackViewProps): JSX.Element {
	const { rack } = useSelectedRack();
	const { width } = useWindowDimensions();
	const isLargeScreenSize = width >= 1920 ? true : false;
	const numCols = rack?.columns || 0;
	const numRows = rack?.rows || 0;
	const changeRackDimensionModalPadding =
		42 + numCols * (isLargeScreenSize ? 24 : 16) + 16;

	const rearrangeRackModalPadding =
		25 + numCols * (isLargeScreenSize ? 24 : 16) + 16;
	const changeDimensionRackWidth =
		getRackContentDimensions(numRows, numCols, isLargeScreenSize).width +
		changeRackDimensionModalPadding;
	const changeDimensionModalWidth = Math.max(changeDimensionRackWidth, 744);
	const rearrangeRackWidth =
		getRackContentDimensions(numRows, numCols, isLargeScreenSize).width +
		rearrangeRackModalPadding;
	const rearrangeModalWidth = Math.max(rearrangeRackWidth, 744);

	const renderRackContent = (disable: boolean) => (
		<RackContents
			rack={rack}
			showEditRackModal={props.showEditRackModal}
			showRearrangeBoxModal={props.showRearrangeBoxModal}
			setShowRearrangeBoxModal={props.setShowRearrangeBoxModal}
			menuDisabled={disable}
		/>
	);

	return (
		<>
			<div className={styles.right}>
				{rack &&
					!props.showEditRackModal &&
					!props.showRearrangeBoxModal && (
						<>{renderRackContent(false)}</>
					)}
			</div>
			{/* Shelf Rack Setting panel modal for changing Rack dimension */}
			<RearrangeModal
				className="rack-view-change-dimension-modal"
				visible={props.showEditRackModal}
				width={changeDimensionModalWidth}
				onOk={() => props.setShowEditRackModal(false)}
				title="Change layout"
			>
				<div
					style={{
						maxWidth: changeDimensionRackWidth,
						display: "flex",
						justifyContent: "center",
						width: "100%",
					}}
				>
					{renderRackContent(true)}
				</div>
			</RearrangeModal>

			{/* Shelf Rack Setting panel modal for rearranging box */}
			<RearrangeModal
				className="rearrange-modal"
				visible={props.showRearrangeBoxModal}
				width={"auto" as any}
				onOk={() => props.setShowRearrangeBoxModal(false)}
			>
				<div
					style={{
						width: rearrangeRackWidth,
					}}
				>
					{renderRackContent(true)}
				</div>
			</RearrangeModal>
		</>
	);
}

type RackContentsProps = {
	// Rack to display
	rack: Rack | undefined;
	/** whether to show editRackModal or not */
	showEditRackModal: boolean;
	/** whether to show rearrangeBoxModal or not */
	showRearrangeBoxModal: boolean;
	setShowRearrangeBoxModal: (visible: boolean) => void;
	additionalBoxes?: Box[];
	onChangeLocation?: (boxId: number, row: number, col: number) => void;
	menuDisabled?: boolean;
};

export function RackContents(props: RackContentsProps): JSX.Element {
	const { rack } = props;
	const { data } = useBoxesQuery(
		rack
			? {
					id: rack.id,
					is_archived: rack.is_archived,
			  }
			: skipToken
	);
	const { freezer } = useFreezer();
	const _boxes = data || [];
	const boxes = props.additionalBoxes
		? _boxes.concat(props.additionalBoxes)
		: _boxes;

	const [isEditing, setEditing] = useState(false);
	// Editing mode row/col selections
	const [selectedCol, setSelectedCol] = useState(-1);
	const [selectedRow, setSelectedRow] = useState(-1);
	// Dropdow fot editing row/col handler
	const [dropdownOpen, setDropdownOpen] = useState<{
		type: "col" | "row";
		idx: number;
	} | null>(null);
	// TODO: change to visible (boolean)
	const [clearModal, setClearModal] = useState<JSX.Element | null>(null);
	const { width } = useWindowDimensions();
	const isLargeScreenSize = width >= 1920 ? true : false;
	// Moving cart for box
	const [movingCartBoxId, setMovingCartBoxId] = useState<number | null>(null);

	const [updateRack] = useRackPatchMutation();
	const [addBox] = useBoxCreateMutation();
	const [deleteBox] = useBoxDeleteMutation();
	const { appendBaseUrl } = useOrganizationRouter();
	const history = useHistory();
	const selectBox = (box: Box) => {
		history.push(
			appendBaseUrl(
				FREEZER_PATHS.BOXES.replace(
					":freezer_id",
					"" + box.location.freezer
				).replace(":box_id", "" + box.id)
			)
		);
	};
	// Turn off editing if changing racks
	useEffect(() => {
		if (isEditing) {
			setEditing(false);
		}
	}, [rack?.id]);

	// Turn on editing when opening the Edit Rack modal or Rearrange Box modal from the Shelf rack setting panel
	useEffect(() => {
		if (
			(props.showEditRackModal === true &&
				!props.showRearrangeBoxModal) ||
			(!props.showEditRackModal === true && props.showRearrangeBoxModal)
		) {
			setEditing(true);
		} else {
			setEditing(false);
		}
	}, [props.showEditRackModal, props.showRearrangeBoxModal]);

	const handleAddBox = (boxToAdd: Partial<Box>, callback: () => void) => {
		if (!rack || !boxToAdd.location?.rack_location) return;
		boxToAdd.location.rack_location.rack = rack.id;
		const box = {
			...boxToAdd,
		} as BoxDataForBackend;

		addBox({ rackId: rack.id, boxData: box })
			.unwrap()
			.then((box) => {
				SegmentTrackEvent(SegmentFreezerEvents.BOX_CREATE, {
					id: box.id,
					num_row_item: box.rows,
					num_col_item: box.columns,
					default_item_type: box.item_type,
					is_item_group: false,
				});
				callback();
			})
			.catch(() =>
				Notification.warning({
					message:
						"Failed to add a box. Try again or contact us if it continues.",
				})
			);
	};

	const [insertRowToRack] = useInsertRackRowMutation();
	const [insertColToRack] = useInsertRackColMutation();
	const insertColumn = (
		/** boolean that indicates whether to insert left or right */
		toLeft: boolean,
		toEnd = false
	) => {
		if (!rack) return;
		if (rack.columns + 1 <= MAX_COLS_RACK) {
			const targetCol = toEnd
				? rack.columns
				: toLeft
				? selectedCol
				: selectedCol + 1;
			insertColToRack({
				id: rack.id,
				colNum: targetCol,
			})
				.unwrap()
				.then(() => {
					if (toLeft) {
						setSelectedCol(selectedCol + 1);
					}
				})
				.catch(() =>
					Notification.warning({
						message:
							"Failed to insert a column. Try again or contact us if it continues",
					})
				);
		}
	};

	const insertRow = (up: boolean, toEnd = false) => {
		if (!rack) return;
		if (rack.rows + 1 <= MAX_ROWS_RACK) {
			const targetRow = toEnd
				? rack.rows
				: up
				? selectedRow
				: selectedRow + 1;

			insertRowToRack({
				id: rack.id,
				rowNum: targetRow,
			})
				.unwrap()
				.then(() => {
					if (up) {
						setSelectedRow(selectedRow - 1);
					}
				})
				.catch(() =>
					Notification.warning({
						message:
							"Failed to insert a row. Try again or contact us if it continues",
					})
				);
		}
	};
	const [deleteRowFromRack] = useDeleteRackRowMutation();
	const [deleteColFromRack] = useDeleteRackColMutation();
	const deleteColumn = (col: number) => {
		if (!rack) return;
		const boxesToDelete = boxes
			.filter((box) => box.location.rack_location?.column === col)
			.map((box) => box.id);
		const handleDelete = () => {
			if (rack.columns - 1 >= MIN_COLS) {
				deleteColFromRack({
					id: rack.id,
					colNum: col,
				})
					.unwrap()
					.then(() => {
						setSelectedCol(selectedCol - 1);
						setClearModal(null);
					})
					.catch(() =>
						Notification.warning({
							message:
								"Failed to delete the column. Try again or contact us if it continues",
						})
					);
			}
		};
		// Alert users the existing boxes will be deleted too
		if (boxesToDelete.length) {
			setClearModal(
				<DeleteModal
					visible
					title="Delete column?"
					onCancel={() => setClearModal(null)}
					onConfirm={handleDelete}
				/>
			);
		} else {
			handleDelete();
		}
	};

	const deleteRow = (row: number) => {
		if (!rack) return;
		const boxesToDelete = boxes
			.filter((box) => box.location.rack_location?.row === row)
			.map((box) => box.id);
		const handleDelete = () => {
			if (rack.rows - 1 >= MIN_ROWS) {
				deleteRowFromRack({
					id: rack.id,
					rowNum: row,
				})
					.unwrap()
					.then(() => {
						setSelectedRow(selectedRow - 1);
						setClearModal(null);
					})
					.catch(() =>
						Notification.warning({
							message:
								"Failed to delete the row. Try again or contact us if it continues",
						})
					);
			}
		};
		// Alert users the existing boxes will be deleted too
		if (boxesToDelete.length) {
			setClearModal(
				<DeleteModal
					visible={true}
					title="Delete row?"
					onCancel={() => setClearModal(null)}
					onConfirm={handleDelete}
				/>
			);
		} else {
			handleDelete();
		}
	};

	/** Remove all the boxes from the targeted row or column */
	const clearRowOrCol = (targetColOrRow: number, isRow: boolean) => {
		const boxesToDelete = isRow
			? boxes.filter(
					(box) => box.location.rack_location?.row === targetColOrRow
			  )
			: boxes.filter(
					(box) =>
						box.location.rack_location?.column === targetColOrRow
			  );
		const handleClear = () => {
			boxesToDelete.map((box) => {
				const boxId = box.id;
				deleteBox(box.id)
					.then(() => {
						SegmentTrackEvent(SegmentFreezerEvents.BOX_DELETE, {
							id: boxId,
						});
					})
					.catch((err) => console.log(err));
			});
			setClearModal(null);
		};
		/** Warn users the boxes will be deleted if they continue */
		if (boxesToDelete.length) {
			setClearModal(
				<DeleteModal
					visible={true}
					title={isRow ? "Clear row?" : "Clear column?"}
					onCancel={() => setClearModal(null)}
					onConfirm={handleClear}
				/>
			);
		} else {
			handleClear();
		}
	};

	const handleColumnMenu: MenuProps["onClick"] = ({ key, domEvent }) => {
		if (!rack) return;
		domEvent.stopPropagation();

		const checkEnoughCols = () => {
			return rack.columns + 1 <= MAX_COLS_RACK;
		};

		switch (key) {
			case "insert left":
				if (checkEnoughCols()) {
					insertColumn(true);
				} else {
					setClearModal(
						<ReachMaxMinModal onClose={() => setClearModal(null)} />
					);
				}
				break;
			case "insert right":
				if (checkEnoughCols()) {
					insertColumn(false);
				} else {
					setClearModal(
						<ReachMaxMinModal onClose={() => setClearModal(null)} />
					);
				}
				break;
			case "delete":
				if (rack.columns - 1 >= MIN_COLS) {
					deleteColumn(selectedCol);
				} else {
					setClearModal(
						<ReachMaxMinModal
							onClose={() => setClearModal(null)}
							isMax={false}
						/>
					);
				}
				break;
			case "clear":
				clearRowOrCol(selectedCol, false);
				break;
			default:
				break;
		}
		setDropdownOpen(null);
	};

	const handleRowMenu: MenuProps["onClick"] = ({ key, domEvent }) => {
		if (!rack) return;

		domEvent.stopPropagation();

		const checkEnoughRows = () => {
			return rack.rows + 1 <= MAX_ROWS_RACK;
		};
		switch (key) {
			case "insert above":
				if (checkEnoughRows()) {
					insertRow(true);
				} else {
					setClearModal(
						<ReachMaxMinModal
							onClose={() => setClearModal(null)}
							isCol={false}
						/>
					);
				}
				break;
			case "insert below":
				if (checkEnoughRows()) {
					insertRow(false);
				} else {
					setClearModal(
						<ReachMaxMinModal
							onClose={() => setClearModal(null)}
							isCol={false}
						/>
					);
				}
				break;
			case "delete":
				if (!(rack.rows - 1 < MIN_ROWS)) {
					deleteRow(selectedRow);
				} else {
					setClearModal(
						<ReachMaxMinModal
							onClose={() => setClearModal(null)}
							isCol={false}
							isMax={false}
						/>
					);
				}
				break;
			case "clear":
				clearRowOrCol(selectedRow, true);
				break;
			default:
				break;
		}
		setDropdownOpen(null);
	};

	const handleMovingCart = (boxData: Box) => {
		if (!rack) return;
		setMovingCartBoxId(null);
	};

	const handleMovingCartRearrange = () => {
		props.setShowRearrangeBoxModal(true);
		setMovingCartBoxId(null);
	};

	const renderColHeader = () => {
		if (!isEditing || !rack) {
			return null;
		}

		const columnMenu: MenuProps["items"] = [
			{
				label: <Typography variant="label">Insert 1 left</Typography>,
				key: "insert left",
			},
			{
				label: <Typography variant="label">Insert 1 right</Typography>,
				key: "insert right",
			},
			{
				type: "divider",
			},
			{
				label: (
					<Typography variant="label" color="danger">
						Delete column
					</Typography>
				),
				key: "delete",
			},
			{
				label: (
					<Typography variant="label" color="danger">
						Clear column
					</Typography>
				),
				key: "clear",
			},
		];

		// Depends on how many cols
		const data = [];

		for (let i = 0; i < rack.columns; i++) {
			const colHeader = (
				<div
					key={i}
					className={
						"col-header" +
						(selectedCol === i ? " col-header__selected" : "")
					}
					onClick={() => {
						if (selectedRow !== -1) {
							setSelectedRow(-1);
						}
						setSelectedCol(i);
					}}
				>
					<DropDown
						menu={{ items: columnMenu, onClick: handleColumnMenu }}
						getPopupContainer={(trigger) =>
							trigger.parentNode as HTMLElement
						}
						open={
							dropdownOpen?.type === "col" &&
							dropdownOpen.idx === i
						}
						onOpenChange={(open) =>
							open
								? setDropdownOpen({
										type: "col",
										idx: i,
								  })
								: setDropdownOpen(null)
						}
					>
						<div
							style={{
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
							}}
						>
							<span>{String.fromCharCode(65 + i)}</span>
							<GenemodIcon
								name="caret-down"
								fill="text-secondary"
								dataCy={`rack-dimension-caret-col-${i}`}
							/>
						</div>
					</DropDown>
				</div>
			);
			data.push(colHeader);
		}

		return (
			<div
				className={cn("top", "genemod-lightmode")}
				style={{
					gridTemplateColumns: `repeat(${rack?.columns}, minmax(20px, 21.5%))`,
				}}
			>
				{data}
			</div>
		);
	};

	const renderRowLabels = () => {
		if (!isEditing || !rack) {
			return null;
		}

		const rowMenu: MenuProps["items"] = [
			{
				label: <Typography variant="label">Insert 1 above</Typography>,
				key: "insert above",
			},
			{
				label: <Typography variant="label">Insert 1 below</Typography>,
				key: "insert below",
			},
			{
				type: "divider",
			},
			{
				label: (
					<Typography variant="label" color="danger">
						Delete row
					</Typography>
				),
				key: "delete",
			},
			{
				label: (
					<Typography variant="label" color="danger">
						Clear row
					</Typography>
				),
				key: "clear",
			},
		];

		const data = [];
		for (let i = 0; i < rack.rows; i++) {
			data.push(
				<div
					key={i}
					className={cn("row-header", "genemod-lightmode", {
						["row-header__selected"]: selectedRow === i,
					})}
					onClick={() => {
						if (selectedCol !== -1) {
							setSelectedCol(-1);
						}
						setSelectedRow(i);
					}}
				>
					<DropDown
						menu={{ items: rowMenu, onClick: handleRowMenu }}
						getPopupContainer={(trigger) =>
							trigger.parentNode as HTMLElement
						}
						open={
							dropdownOpen?.type === "row" &&
							dropdownOpen.idx === i
						}
						onOpenChange={(open) =>
							open
								? setDropdownOpen({
										type: "row",
										idx: i,
								  })
								: setDropdownOpen(null)
						}
					>
						<div
							style={{
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
							}}
						>
							<span style={{ userSelect: "none" }}>{i + 1}</span>
							<GenemodIcon
								name="caret-down"
								fill="text-secondary"
								dataCy={`rack-dimension-caret-row-${i}`}
							/>
						</div>
					</DropDown>
				</div>
			);
		}

		return <div className="left-col">{data}</div>;
	};

	const renderCells = () => {
		if (!rack) return;
		const cells = [];
		let uniqueIndex = 0;
		for (let i = 0; i < rack.rows; i++) {
			for (let j = 0; j < rack.columns; j++) {
				uniqueIndex++;
				const box =
					boxes.filter(
						(box) =>
							box.location?.rack_location?.rack === rack.id &&
							box.location?.rack_location?.row === i &&
							box.location?.rack_location?.column === j
					)[0] || null;
				cells.push(
					<BoxTableCell
						key={uniqueIndex}
						setMovingCartBoxId={setMovingCartBoxId}
						box={box}
						row={i}
						col={j}
						handleAddBox={handleAddBox}
						viewOnly={rack.is_archived || props.showEditRackModal}
						onClick={() => selectBox(box)}
						showEditRackModal={props.showEditRackModal}
						showRearrangeBoxModal={props.showRearrangeBoxModal}
						onChangeLocation={props.onChangeLocation}
					/>
				);
			}
		}
		return cells;
	};

	let rackContentHorizontalPadding = 0;
	let rackContentVerticalPadding = 0;
	let rackContentWidth = 0;
	let dims = { width: 0, height: 0 };

	if (rack) {
		dims = getRackContentDimensions(
			rack.rows,
			rack.columns,
			isLargeScreenSize
		);
		rackContentHorizontalPadding =
			24 + 2 * rack.columns * (isLargeScreenSize ? 12 : 4);
		rackContentVerticalPadding =
			24 + 2 * rack.rows * (isLargeScreenSize ? 12 : 8);
		rackContentWidth = dims.width + rackContentHorizontalPadding;
	}

	return (
		<div className={cn(styles.rackView)}>
			<div className={styles.info}>
				<GenemodIcon name="rack-grid" color="text-secondary-v2" />
				<ClickToEdit
					className={styles.clickToEdit}
					value={rack?.name || ""}
					onComplete={(val) => {
						if (!rack) return;
						updateRack({ id: rack.id, name: val });
					}}
					variant="headline5"
					color="text-secondary-v2"
					addConfirmAndCancel={false}
					component="input"
					lightBackground
					cancelEditionIfEmpty
					tabToSave
				/>
				{!rack || props.menuDisabled ? null : (
					<RackMenu
						freezer={freezer}
						viewOnly={false}
						rackOrCategory={rack}
					>
						<GenemodIcon
							name="meatballs"
							style={{
								marginLeft: "auto",
								minHeight: 32,
								minWidth: 32,
							}}
							className={styles.rackMenu}
						/>
					</RackMenu>
				)}
			</div>
			{props.showEditRackModal && renderColHeader()}
			<div className={styles.boxAndColumns}>
				{props.showEditRackModal && renderRowLabels()}
				<div
					className={styles.contents}
					style={{
						gridTemplateColumns: `repeat(${rack?.columns}, minmax(20px, 21.5%))`,
					}}
				>
					{renderCells()}
				</div>
			</div>
			{clearModal}
			<BoxMovingCart
				boxId={movingCartBoxId}
				onClose={() => setMovingCartBoxId(null)}
				onMoveBox={handleMovingCart}
				onRearrange={handleMovingCartRearrange}
			/>
		</div>
	);
}

type reachMaxMinModalProps = {
	onClose: () => void;
	isMax?: boolean;
	isCol?: boolean;
};
/**
 *  A modal that warns users whether they've reached the max/min of rack's dimensions
 **/
function ReachMaxMinModal({
	onClose,
	isMax = true,
	isCol = true,
}: reachMaxMinModalProps): JSX.Element {
	let modalTitle = "Must have at least one column";
	let modalContent = isCol
		? "You must have at least one column in a rack."
		: "You must have at least one row in a rack.";
	if (isMax) {
		modalTitle = isCol
			? "Reach maximum number of columns"
			: "Reach maximum number of rows";
		modalContent = isCol
			? "You reached the maximum number of columns in a rack."
			: "You reached the maximum number of rows in a rack.";
	}
	return (
		<Modal
			visible
			width={496}
			title={modalTitle}
			onOk={onClose}
			onCancel={onClose}
			hideCancelButton
		>
			{modalContent}
		</Modal>
	);
}
function appendBaseUrl(arg0: any): any {
	throw new Error("Function not implemented.");
}
