import { Color } from "@common/styles/Colors";
import { NominalType, AtLeastId } from "@helpers/TypeHelpers";
import { Avatar, TeamMemberAvatar } from "./User";
import { OrganizationUserId, SerializedSharedObject } from "@common/types";
import { ProjectSharingPreferences } from "@containers/ProjectManagement/components/ProjectSharingPreferences/ProjectSharingPreferences";

export enum ProjectColorEnum {
	VIOLET = 0,
	NAVY,
	MINT,
	GREEN,
	PINK,
	RED,
	GINGER,
	YELLOW,
}

// Eg: "file_123,folder_456,folder_789,file_1011"
export type SortedChildrenBlob = NominalType<"SortedChildrenBlob", string>;
export type SortedChild =
	| { type: "file"; id: number }
	| { type: "folder"; id: number };

export type ProjectId = NominalType<"ProjectId", number>;

export enum ProjectFromCompletedPermissionsEnum {
	ALL = 1,
	WORKSPACE_ADMINS,
}

export type ProjectProps = {
	id: ProjectId;
	name: string;
	key: string;
	users: TeamMemberAvatar[];
	created_at: string;
	updated_at: string;
	is_archived: boolean;
	created_by: Avatar;
	updated_by: Avatar;
	navigation: Record<string, unknown>;
	is_locked: boolean;
	color: ProjectColorEnum;
	experiment_count: number;
	sorted_children: SortedChild[];
	access: SerializedSharedObject["access"];
	is_shared: boolean;
	sharing: ProjectSharingPreferences;
	materials_template: number;
	from_completed: ProjectFromCompletedPermissionsEnum;
};

export type ProjectPropsDto = Omit<ProjectProps, "sorted_children"> & {
	sorted_children: SortedChildrenBlob;
};

export const projectPropsDtoToProjectProps = (
	dto: ProjectPropsDto
): ProjectProps => {
	return {
		...dto,
		sorted_children: sortedChildrenParse(dto.sorted_children),
		is_shared:
			dto.access.organization_users.length > 1 ||
			dto.access.workspaces.length > 0 ||
			dto.access.organization_users.length > 0,
		sharing: (() => {
			if (dto.access.organizations?.length) {
				return "org";
			} else if (dto.access.workspaces?.length) {
				return "workspace";
			}
			return "personal";
		})(),
	};
};

type PatchProjectData<P extends { id: number }> = AtLeastId<
	P & {
		user_ids: OrganizationUserId[];
		color: ProjectColorEnum;
	}
>;

export const projectPropsToProjectPropsDto = (
	props: PatchProjectData<ProjectProps>
): PatchProjectData<ProjectPropsDto> => {
	const copy = { ...props } as unknown as PatchProjectData<ProjectPropsDto>;
	if (props.sorted_children) {
		copy["sorted_children"] = sortedChildrenStringify(
			props.sorted_children
		);
	}
	return copy;
};

/**
 * Parse a string into a list of sorted children
 */
const sortedChildrenParse = (blob: SortedChildrenBlob): SortedChild[] => {
	if (blob === ("" as SortedChildrenBlob)) return [];
	try {
		const parsed = blob.split(",") as string[];
		return parsed.map((item) => {
			const [type, id] = item.split("_");
			return {
				type: type as "file" | "folder",
				id: parseInt(id),
			};
		});
	} catch (e) {
		/* empty */
	}
	return [];
};

/**
 * Stringify a list of sorted children into a string
 */
const sortedChildrenStringify = (
	children: SortedChild[]
): SortedChildrenBlob => {
	return children
		.map(({ type, id }) => `${type}_${id}`)
		.join(",") as SortedChildrenBlob;
};

export const ProjectColorEnumToColor = Object.freeze({
	[ProjectColorEnum.VIOLET]: "violet-05",
	[ProjectColorEnum.NAVY]: "royal-blue-05",
	[ProjectColorEnum.MINT]: "cyan-05",
	[ProjectColorEnum.PINK]: "green-05",
	[ProjectColorEnum.GREEN]: "pink-05",
	[ProjectColorEnum.RED]: "dust-red-05",
	[ProjectColorEnum.GINGER]: "desert-05",
	[ProjectColorEnum.YELLOW]: "gold-05",
} as Record<ProjectColorEnum, Color>);
