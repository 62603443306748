import { useSortable } from "@dnd-kit/sortable";
import React, { useEffect, useMemo, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import styles from "./MaterialTemplateCollapsable.module.scss";
import {
	ButtonV2,
	GenemodIcon,
	RestrictionWarning,
	Typography,
} from "@common/components";
import InputV3 from "@common/components/InputV3/InputV3";
import { usePatchMaterialTemplateMutation } from "@redux/ProjectManagement/PmApiSlice";
import { ExperimentMaterialTemplateSchema } from "@common/types";
import classNames from "classnames";

type Props = {
	templateId: number;
	schema: ExperimentMaterialTemplateSchema;
	premade: boolean;
	schemaObj: Record<string, ExperimentMaterialTemplateSchema>;
	col: string;
	viewOnly?: boolean;
	last?: boolean;
};

const MaterialTemplateCollapsableRow = ({
	templateId,
	schema,
	premade,
	schemaObj,
	col,
	viewOnly,
	last,
}: Props) => {
	const [patchTemplate] = usePatchMaterialTemplateMutation();
	const [fieldName, setFieldName] = useState(schema.label);
	const [editing, setEditing] = useState(false);
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: col });
	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	const onKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			setEditing(false);
			setFieldName(schema.label);
		}
	};

	useEffect(() => {
		if (editing) {
			window.addEventListener("keydown", onKeyDown);
		} else {
			window.removeEventListener("keydown", onKeyDown);
		}

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [editing]);

	useEffect(() => {
		setFieldName(schema.label);
	}, [schema]);

	const style: React.CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
		...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
	};

	const onSave = () => {
		if (fieldName !== schema.label) {
			const newObj = { ...schemaObj };
			newObj[schema.label] = {
				...newObj[schema.label],
				label: fieldName,
			};
			patchTemplate({
				id: templateId,
				schema: JSON.stringify(Object.values(newObj)),
			}).finally(() => {
				setEditing(false);
			});
		} else {
			setEditing(false);
			setFieldName(schema.label);
		}
	};

	const onRemove = () => {
		const newObj = { ...schemaObj };
		delete newObj[schema.label];
		patchTemplate({
			id: templateId,
			schema: JSON.stringify(Object.values(newObj)),
		}).finally(() => {
			setEditing(false);
		});
	};

	const renderActions = () => {
		if (editing) {
			return (
				<div className={styles.editing}>
					<ButtonV2
						icon="swoosh-check"
						type="text"
						iconColor="text-secondary-v2"
						onClick={onSave}
					>
						<Typography color="text-secondary-v2">Save</Typography>
					</ButtonV2>
					<ButtonV2
						type="text"
						onClick={() => {
							setShowDeleteWarning(true);
						}}
					>
						<Typography color="danger">Delete</Typography>
					</ButtonV2>
				</div>
			);
		} else {
			return (
				<div
					className={styles.edit}
					onClick={() => {
						setEditing(true);
					}}
				>
					<GenemodIcon name="pencil" color="text-secondary-v2" />
					<Typography>Edit</Typography>
				</div>
			);
		}
	};
	return (
		<div className={classNames({ [styles.rowContainer]: !last })}>
			<div className={styles.row}>
				{!viewOnly && (
					<div
						ref={setNodeRef}
						style={style}
						{...attributes}
						{...listeners}
					>
						<GenemodIcon
							name="hamburger"
							style={{ marginRight: 4 }}
						/>
					</div>
				)}
				{editing ? (
					<InputV3
						value={fieldName}
						onChange={(e) => setFieldName(e.target.value)}
						wrapperProps={{ style: { width: "100%" } }}
					/>
				) : (
					<Typography style={{ cursor: "pointer" }}>
						{fieldName}
					</Typography>
				)}
				{!premade && !viewOnly && renderActions()}
			</div>
			{editing && showDeleteWarning && (
				<RestrictionWarning
					icon="error-f"
					showActions
					okButtonText="Cancel"
					cancelButtonText="Delete column"
					style={{ marginLeft: 24 }}
					onOk={() => setEditing(false)}
					onCancel={onRemove}
				>
					Deleting this column will permanently remove all its
					associated data from this template.{" "}
					<b>This action cannot be undone.</b>{" "}
				</RestrictionWarning>
			)}
		</div>
	);
};

export default MaterialTemplateCollapsableRow;
