import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

/**
 * Initializer script that sets the Meticulous environment
 * @function
 * */
export const initMeticulous = async () => {
    const nodeEnv = process.env.REACT_APP_NODE_ENV?.toLowerCase() || "";
	// only run the meticulous initialization when it's in dev or qa
	if (
		nodeEnv === "qa" ||
		nodeEnv === "dev"
	) {
		await tryLoadAndStartRecorder({
			recordingToken: 'bEOk72l2FEcIhTfkF7hpVGF2tuvFKiNfqVQ7d1zW',
			isProduction: false,
		});
	}
};
