import React, { useEffect, useState } from "react";
import { KeyListener, Modal, Typography } from "@components";

/** Simple Modal component to delete / clear rows or cols */
export default function DeleteModal(_props: {
	visible: boolean;
	title: string;
	message?: string | React.ReactNode;
	onCancel: () => void;
	onConfirm: () => void;
	dataCy?: string;
	showUndo?: boolean;
	hideCancelButton?: boolean;
	okText?: string;
	dangerButton?: boolean;
}): JSX.Element {
	const {
		showUndo = true,
		hideCancelButton = true,
		okText = "Delete",
		...props
	} = _props;

	useEffect(() => {
		setLoading(false);
	}, [props.visible]);

	const [isLoading, setLoading] = useState(false);
	return (
		<Modal
			title={props.title}
			visible={props.visible}
			onOk={props.onConfirm}
			onCancel={props.onCancel}
			destroyOnClose
			okText={okText}
			okButtonProps={{
				loading: isLoading,
				style: props.dangerButton
					? { backgroundColor: "var(--danger)" }
					: undefined,
			}}
			hideCancelButton={hideCancelButton}
			dataCy={props.dataCy}
		>
			<KeyListener hotkeys={[{ key: "Enter", onPress: props.onConfirm }]}>
				<Typography>
					{props.message
						? props.message
						: "All the contents inside will be deleted. Proceed?"}
					<span style={{ fontWeight: "bold" }}>
						{" "}
						{showUndo && "There is no undo."}
					</span>
				</Typography>
			</KeyListener>
		</Modal>
	);
}
