import { Link, LinkProps } from "@common/helpers/Hooks/UseRouterDom";
import {
	AccountKeys,
	AllGenemodLinkKeys,
	AllGenemodLinkPrefixes,
	EquipmentKeys,
	FrameworkKeys,
	FreezerKeys,
	GenemodLinkType,
	PmKeys,
	UUID,
} from "@common/types";
import { Demo, DemoSection, DemoWrapper } from "@components";
import { actionOnTypeLiteral } from "@helpers/TypeHelpers";
import { useOrganizationRouter } from "@root/AppRouter";
import {
	ACCOUNT_SETTINGS_PATHS,
	EQUIPMENTS_PATHS,
	FRAMEWORK_PATHS,
	PM_ROUTES,
} from "@root/routes";
import cn from "classnames";
import { LocationDescriptorObject } from "history";
import React from "react";
import { FREEZER_PATHS } from "../../../containers/Freezer/index";
import styles from "./GenemodLink.module.scss";

/**
 * Extends link props.
 */
type GenemodLinkProps<S = unknown> = Omit<LinkProps<S>, "to"> &
	React.RefAttributes<HTMLAnchorElement> & {
		to: GenemodLinkType;
		/** id which is replace with :id */
		objectId?: number | UUID;
		/** whether to show underline when hover on children */
		hoverUnderline?: boolean;
		/**
		 * Provide text replacement parameters here. Eg: {":freezer_id": 123}
		 */
		pathParams?: Record<string, string | number>;
		searchParams?: Record<string, string | number>;
		toProps?: LocationDescriptorObject;
	};

/**
 * Creates a simple react-router-dom link with typed "to" field. Also appends base
 * url for you.
 */
const GenemodLink = <S,>({
	to,
	objectId,
	pathParams = {},
	searchParams = {},
	hoverUnderline = true,
	toProps = {},
	...props
}: GenemodLinkProps<S>): JSX.Element => {
	const { appendBaseUrlAndParams } = useOrganizationRouter();

	if (to === "none") {
		/**
		 * if to is "none", just treat as normal children instead of Link
		 */
		return <>{props.children}</>;
	}
	// Break up the given "to" prop into the type and the actual key to the paths object.
	const [type, key] = to.split(/_(.*)/s) as [
		AllGenemodLinkPrefixes,
		AllGenemodLinkKeys
	];

	// Get path
	let path = actionOnTypeLiteral(type)({
		ACCOUNT: () => ACCOUNT_SETTINGS_PATHS[key as AccountKeys].route,
		PM: () => PM_ROUTES[key as PmKeys],
		FREEZER: () => FREEZER_PATHS[key as FreezerKeys],
		EQUIPMENT: () => EQUIPMENTS_PATHS[key as EquipmentKeys],
		FRAMEWORK: () => FRAMEWORK_PATHS[key as FrameworkKeys],
	});

	if (objectId) {
		path = path.replace(":id", "" + objectId);
	}
	// Add path params
	path = Object.entries(pathParams).reduce(
		(path, [param, value]) => path.replace(param, value + ""),
		path
	);

	// Add search params
	path = appendBaseUrlAndParams(path, searchParams);

	return (
		<Link
			to={{
				pathname: path,
				...toProps,
			}}
			{...props}
			className={cn(props.className, styles.link, {
				[styles.link__underline]: hoverUnderline,
			})}
		/>
	);
};

export default GenemodLink;

export const GENEMOD_LINK_DEMO = (): JSX.Element => {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Demo genemod link"
					description="Note that org router returns the wrong thing in the demo pages. Just hover and see the preview link."
				>
					<GenemodLink to="FREEZER_HOME">Freezer home</GenemodLink>
					<br />
					<GenemodLink
						to="FREEZER_BOXES"
						pathParams={{ ":freezer_id": 123, ":box_id": 456 }}
					>
						Freezer 123 Box 456
					</GenemodLink>
					<br />
					<GenemodLink
						to="FREEZER_CONTENTS"
						objectId={123}
						searchParams={{ test: "helloworld", test2: "genemod" }}
					>
						Freezer id 123 with search params
					</GenemodLink>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
};
