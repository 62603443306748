import { axios } from "@API";
import { Tool } from "@classes/SidebarSettings";
import { Link, useLocation } from "@common/helpers/Hooks/UseRouterDom";
import {
	DropDown,
	GenemodIcon,
	Modal,
	Popover,
	SVG,
	TeamAvatar,
	Typography,
	UserAvatar,
} from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	useCurrentUserQuery,
	useSidebarSettingsPutMutation,
	useSidebarSettingsQuery,
} from "@redux/user/UserApi";
import { useOrganizationRouter } from "@root/AppRouter";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import FreePlan from "./FreePlan";
import "./Sidebar.scss";
import FREEZER_ICON from "./assets/Freezer-v2.svg";
import DASHBOARD_ICON from "./assets/Home-v2.svg";
import PM_ICON from "./assets/PM-v2.svg";
import SEQUENCE_ICON from "./assets/SE-v2.svg";
import ADD_ICON from "./assets/add-v2.svg";
import CHECKMARK from "./assets/checkmark.svg";
import CORE_ICON from "./assets/microscope-v2.svg";
import PIN_FILLED from "./assets/pin_filled.svg";
import PIN_UNFILLED from "./assets/pin_unfilled.svg";
import styles from "./index.module.scss";

const sidebar = [
	{
		type: "button",
		key: "dashboard",
		label: "Dashboard",
		tool: "DASHBOARD",
		icon: DASHBOARD_ICON,
	},
	{
		type: "button",
		label: "Projects",
		key: "project_management",
		icon: PM_ICON,
		tool: "PROJECT_MANAGEMENT",
		description:
			"Establish experimental protocols through sharing and dividing projects. Monitor project activity with real-time updates and high-level organization.",
	},
	{
		type: "button",
		label: "Inventory",
		key: "freezer",
		icon: FREEZER_ICON,
		tool: "INVENTORY",
		description:
			"Record, collect, and visually monitor reagents/samples through structured and customizable labels at the fridges/shelves/racks/boxes levels. Locate items through the universal search feature and easily retrieve needed items from their locations.",
	},
	{
		type: "button",
		label: "DNA",
		key: "dna",
		tool: "DNA",
		description:
			"Modify DNA sequences and calculate their properties in real-time. Sequencing data can be shared or stored in the workspace for later use.",
		icon: SEQUENCE_ICON,
	},
	{
		type: "button",
		label: "Core",
		key: "equipments",
		tool: "EQUIPMENTS",
		description: "Core Facility.",
		icon: CORE_ICON,
	},
] as const;
type SidebarItem = (typeof sidebar)[number];
type ToolSidebarItem = (typeof sidebar)[1 | 2 | 3];

export default function Sidebar() {
	const [rearranging, setRearranging] = useState(false);
	const { data: sidebarSettings } = useSidebarSettingsQuery();
	const { order } = sidebarSettings || {};
	const [updateSidebarSettings] = useSidebarSettingsPutMutation();

	const onUnpin = (tool: Tool) => {
		if (!sidebarSettings) return;
		updateSidebarSettings({
			...sidebarSettings,
			[tool]: false,
			order: sidebarSettings.order.filter((t) => t !== tool),
		});
	};

	const renderMenu = () => {
		if (!sidebarSettings || !order) return <></>;
		const tools = order
			.filter((tool) => sidebarSettings[tool])
			.map((tool) => sidebar.find((x) => x.key === tool) as SidebarItem)
			.map((item, index) => (
				<SidebarButton
					info={item}
					key={item.key}
					onUnpin={onUnpin}
					onRearrange={() => setRearranging(true)}
					isRearrangeable={rearranging}
					index={index}
					rearrangeTools={rearrangeTools}
				/>
			));

		const dashboardInfo = sidebar.filter((x) => x.key === "dashboard")[0];
		return [
			<SidebarButton info={dashboardInfo} key={dashboardInfo.key} />,
			...tools,
		];
	};

	/**
	 * Called after user finishes rearranging icons
	 * @function
	 */
	const onRearrange = () => {
		setRearranging(false);
	};

	/**
	 * Called when user rearranges tools
	 * @function
	 * @param {string} dragKey Key of the dragged tool
	 * @param {string} dropKey Key of the dropped/hovered tool
	 */
	const rearrangeTools = (dragKey: Tool, dropKey: Tool) => {
		if (!order || !sidebarSettings) return;
		const dragIndex = order.indexOf(dragKey);
		const dropIndex = order.indexOf(dropKey);
		const newOrder = [...order];
		newOrder.splice(dropIndex, 0, newOrder.splice(dragIndex, 1)[0]);
		updateSidebarSettings({ ...sidebarSettings, order: newOrder });
	};

	const { openChangeWorkspacePanel } = useCommonModalState(
		"changeWorkspacePanel"
	);

	return (
		<div className={"genemod-sidebar"}>
			<DndProvider backend={HTML5Backend}>
				<div
					className="team-avatar-container"
					onClick={() => openChangeWorkspacePanel()}
				>
					<TeamAvatar />
				</div>
				<div className={styles.divider} />
				{renderMenu()}
				<div className={styles.divider} style={{ marginTop: 8 }} />
				{rearranging ? (
					<div
						className={classNames(
							"sidebar-btn",
							styles.rearrangeDone
						)}
						onClick={onRearrange}
					>
						<div className="btn-icon">
							<SVG className="icon" src={CHECKMARK} />
						</div>
						<div className={styles.sidebarBtnLabel}>Done</div>
					</div>
				) : (
					<AddToolsButton />
				)}
				<div className="end-btns">
					<FreePlan />
					<MenuAvatar />
				</div>
			</DndProvider>
		</div>
	);
}

/**
 * Sidebar icon and modal for adding tools to the
 * sidebar.
 * @function
 */
function AddToolsButton() {
	const { data: sidebarSettings } = useSidebarSettingsQuery();
	const [updateSidebarSettings] = useSidebarSettingsPutMutation();

	const [showModal, setShowModal] = useState(false);
	/**
	 * Toggles a toolbar tool
	 * @function
	 * @param {string} tool Key of the tool
	 * @param {boolean} active Active state of tool
	 * */
	const onToolToggle = (tool: Tool, active: boolean) => {
		if (!sidebarSettings) return;
		updateSidebarSettings({ ...sidebarSettings, [tool]: !active });
	};

	/**
	 * Sets the redux settings object with the applied changes.
	 * Requires props.setSidebarSettings
	 * @function
	 */
	const onApply = () => {
		setShowModal(false);
	};

	return (
		<>
			<div
				className={classNames("sidebar-btn")}
				onClick={() => setShowModal(true)}
				data-cy="add-sidebar-button"
			>
				<div className="btn-icon">
					<SVG src={ADD_ICON} />
				</div>
				<div className={styles.sidebarBtnLabel}>Add</div>
			</div>
			<Modal
				visible={showModal}
				hideCancelButton
				onCancel={() => setShowModal(false)}
				okText="Done"
				title={"Add tools to Toolbar"}
				onOk={onApply}
				subtitle="Pinned tools will appear on the Toolbar"
				dataCy="add-tools"
			>
				{sidebar
					.filter((x) => x.key !== "dashboard")
					.map((item) => item as ToolSidebarItem)
					.map((item) => {
						const isActive =
							!!sidebarSettings && sidebarSettings[item.key];
						return (
							<div className={styles.addToolRow} key={item.key}>
								<div>
									<div className={styles.addToolIcon}>
										<SVG src={item.icon} />
									</div>
								</div>
								<div>
									<Typography
										variant="headline"
										bold
										style={{ marginBottom: "8px" }}
									>
										{item.label}
									</Typography>
									<Typography>{item.description}</Typography>
								</div>
								<SVG
									src={isActive ? PIN_FILLED : PIN_UNFILLED}
									className={styles.pin}
									onClick={() =>
										onToolToggle(item.key, isActive)
									}
									data-cy={"tool-toggle-" + item.key}
								/>
							</div>
						);
					})}
			</Modal>
		</>
	);
}

type SidebarButtonProps = {
	info: SidebarItem;
	isRearrangeable?: boolean;
	index?: number;
	stable?: boolean;
	onUnpin?: (tool: Tool) => void;
	onRearrange?: () => void;
	rearrangeTools?: (dragKey: Tool, dropKey: Tool) => void;
};

/**
 * Sidebar menu button
 * @param {object} props
 * @param {object} props.info Sidebar button info
 * @param {object} props.onUnpin Called when user unpins tool
 * @param {function} props.onRearrange Called when user clicks rearrange
 * @param {boolean} props.isRearrangeable True if rearranging is active
 * @param {number} props.index Index of the tool in the sidebar
 * @param {function} props.rearrangeTools Called when a tool is rearranged
 * @param {boolean} [props.stable = true] If the icon could be rearrange or unpin
 */
function SidebarButton(props: SidebarButtonProps) {
	const { label, key, tool } = props.info;
	const orgRouter = useOrganizationRouter();
	const location = useLocation();
	const href = orgRouter.getToolRoute(tool);
	const selected = location.pathname.includes(href);
	/**
	 * Handles context menu clicks
	 * @function
	 */
	const onMenuClick = (e: ClickParam) => {
		switch (e.key) {
			case "unpin":
				props.onUnpin?.(key as Tool);
				break;
			case "rearrange":
				props.onRearrange?.();
				break;
			default:
				break;
		}
	};

	const { Item } = Menu;
	const dropdownMenu = (
		<Menu onClick={onMenuClick} data-cy="sidebar-btn-menu">
			<Item
				key="unpin"
				disabled={key === "dashboard"}
				style={{ paddingLeft: "8px", paddingRight: "32px" }}
				data-cy="sidebar-btn-menu-unpin"
			>
				<GenemodIcon
					name="unpin"
					fill="text-secondary"
					style={{ marginRight: 8 }}
				/>
				Unpin
			</Item>
			<Item
				key="rearrange"
				disabled={key === "dashboard"}
				style={{ paddingLeft: "8px", paddingRight: "32px" }}
				data-cy="sidebar-btn-menu-rearrange"
			>
				<GenemodIcon name="rearrange" style={{ marginRight: 8 }} />
				Rearrange
			</Item>
		</Menu>
	);

	const renderSidebarButton = () => (
		<div
			className={classNames("sidebar-btn", {
				"sidebar-btn__selected": selected,
				[styles.sidebarBtnSelected]: selected,
			})}
		>
			<Link
				data-cy={key + "-sidebar-button"}
				className={styles.sidebarBtnLink}
				to={{
					pathname: href,
					state: { fromDashboard: true },
				}}
			>
				<div className="btn-icon">
					<SVG className="icon" src={props.info.icon} />
				</div>
				<div className={styles.sidebarBtnLabel}>{label}</div>
			</Link>
		</div>
	);

	return (
		<>
			{!props.isRearrangeable ? (
				props.stable ? (
					renderSidebarButton()
				) : (
					<DropDown
						overlay={dropdownMenu}
						trigger={["contextMenu"]}
						placement="bottomLeft"
						size="normal"
						getPopupContainer={() => document.body}
					>
						{renderSidebarButton()}
					</DropDown>
				)
			) : (
				<SidebarDragDropButton
					info={props.info}
					index={props.index}
					rearrangeTools={props.rearrangeTools}
				/>
			)}
		</>
	);
}

type SidebarDragDropButtonProps = {
	info: SidebarItem;
	index?: number;
	rearrangeTools?: (dragKey: Tool, dropKey: Tool) => void;
};
function SidebarDragDropButton(props: SidebarDragDropButtonProps) {
	const { label, key } = props.info;
	const { index = 0 } = props;
	const btnRef = useRef<HTMLDivElement>(null);
	const DRAG_TYPE = "sidebar-button";
	const [, drag] = useDrag({
		item: {
			type: DRAG_TYPE,
			key,
			index,
		},
		canDrag: () => key !== "dashboard",
	});

	const [, drop] = useDrop({
		accept: DRAG_TYPE,
		canDrop: () => key !== "dashboard",
		hover: (item, monitor) => {
			if (!monitor.canDrop() || !btnRef.current) return;

			const dragIndex = (item as any).index;
			if (index === dragIndex) return;

			const dropRect = btnRef.current?.getBoundingClientRect();
			// Center of hovered element
			const dropCenter = (dropRect.bottom - dropRect.top) / 2;

			// User's mouse position
			const mouseOffset = monitor.getClientOffset();
			// Moving up or down?
			const mouseY = (mouseOffset?.y || 0) - dropRect.top;

			if (dragIndex < index && mouseY < dropCenter) return;
			if (dragIndex > index && dropCenter < mouseY) return;

			props.rearrangeTools?.((item as any).key as Tool, key as Tool);
			// Update item to save computation
			(item as any).index = index;
		},
	});

	drag(drop(btnRef));

	return (
		<div
			ref={btnRef}
			className={classNames("sidebar-btn", "rearrangeable")}
		>
			<div className="btn-icon">
				<SVG className="icon" src={props.info.icon} />
			</div>
			<div className={styles.sidebarBtnLabel}>{label}</div>
		</div>
	);
}

function MenuAvatar() {
	const { data: user } = useCurrentUserQuery();
	const [visible, setVisible] = useState(false);
	const orgRouter = useOrganizationRouter();
	const { isContactUsPanelVisible, openContactUsPanel, closeContactUsPanel } =
		useCommonModalState("contactUsPanel");

	const menu = (
		<div
			className="sidebar__useravatar-menu"
			onClick={() => setVisible(false)}
		>
			<Link
				to={`${orgRouter.getToolRoute("ACCOUNT_SETTINGS")}/profile`}
				data-cy="useravatar-menu-account-settings-link"
			>
				Account settings
			</Link>
			<Link
				to={`${orgRouter.getToolRoute("FRAMEWORK")}/material-templates`}
				data-cy="framework-link"
			>
				Framework
			</Link>

			<a
				onClick={() => {
					isContactUsPanelVisible
						? closeContactUsPanel()
						: openContactUsPanel();
				}}
				data-cy="useravatar-menu-contact-us-link"
			>
				Contact us
			</a>
			<Link to={"/logout"} data-cy="useravatar-menu-logout-link">
				Logout
			</Link>
			{process.env.REACT_APP_NODE_ENV === "local" && (
				<Link
					to={{
						pathname: `http://localhost:8000/?Authorization=${axios?.defaults?.headers?.common?.Authorization}&Genemod-WorkspaceId=${axios?.defaults?.headers?.common?.["Genemod-WorkspaceId"]}&Genemod-OrganizationId=${axios?.defaults?.headers?.common?.["Genemod-OrganizationId"]}`,
					}}
					target="_blank"
					data-cy="useravatar-menu-swagger-link"
				>
					Swagger
				</Link>
			)}
		</div>
	);
	return (
		<Popover
			visible={visible}
			onVisibleChange={(v: boolean) => setVisible(v)}
			content={menu}
			placement="right"
			trigger="click"
			overlayClassName="sidebar__popover"
		>
			<div className="sidebar-btn" data-cy="account-sidebar-button">
				<div className="btn-icon">
					<UserAvatar
						user={user}
						size={18}
						avatarStyle={{ cursor: "pointer" }}
					/>
				</div>
				<div className={styles.sidebarBtnLabel}>Account</div>
			</div>
		</Popover>
	);
}
