import React, { useRef, useMemo, useState } from "react";
import { Tag, TagProps } from "antdv5";
import styles from "./index.module.scss";
import { GenemodIcon, IconName, Typography } from "@components";
import { Input } from "antd";
import { AutoComplete } from "antdv4";
import classNames from "classnames";
import { DataSourceItemObject } from "antdv4/lib/auto-complete";
import cn from "classnames";
import { Color, ColorOptions } from "@common/styles/Colors";

export type LabelProps = Omit<TagProps, "closable" | "icon"> & {
	onClose?: () => void;
	type?: "default" | "static";
	isActive?: boolean;
	dataCy?: string;
	noElipsis?: boolean;
	size?: "medium" | "large";
	color?: ColorOptions;
	genemodIcon?: IconName;
	checked?: boolean;
	onClick?: (e: React.MouseEventHandler<HTMLSpanElement> | undefined) => void;
	checkable?: boolean;
};
export default function LabelV2(props: LabelProps): JSX.Element {
	const {
		children,
		onClose,
		className,
		type = "default",
		size = "medium",
		isActive,
		noElipsis = false,
		color,
		bordered,
		genemodIcon,
		checked,
		onClick,
		checkable,
	} = props;

	const borderColor = () => {
		if (checked) {
			return "var(--brand-08)";
		} else if (color) {
			return "var(--" + color + "-04)";
		}
		return "var(--gray-04)";
	};

	const textColor = (): Color => {
		if (checked) {
			return "text-on-color";
		} else if (color) {
			return (color + "-06") as Color;
		}
		return "text-secondary-v2";
	};

	const backgroundColor = () => {
		if (checkable && !checked) {
			return "var(--layer-02-v2)";
		}
		if (checked) {
			if (checkable) {
				return "var(--informative)";
			}
			return "var(--brand-08) !important";
		} else if (color) {
			return "var(--" + color + "-01)";
		}
		return "var(--gray-03)";
	};

	return (
		<Tag
			{...props}
			className={cn(
				styles.genemodLabel,
				styles[type],
				{
					[styles.active]: isActive,
					[styles.genemodLabelNoElipsis]: noElipsis,
				},
				className
			)}
			closable={!!onClose}
			onClose={onClose}
			closeIcon={<GenemodIcon name="exit" fill="gray-08" size="small" />}
			data-cy={`${props.dataCy}-label`}
			style={{
				color: textColor(),
				backgroundColor: backgroundColor(),
				border: checkable
					? "1px solid var(--border-invisible-v2)"
					: bordered
					? `1px solid ${borderColor()}`
					: "none",
				height: size === "large" ? 30 : 22,
			}}
			icon={
				genemodIcon ? (
					<GenemodIcon
						name={genemodIcon}
						fill={textColor()}
						color={textColor()}
						stroke={textColor()}
						size="default"
					/>
				) : null
			}
			onClick={onClick}
		>
			<Typography
				variant={size === "large" ? "regular" : "footnote"}
				color={textColor()}
			>
				{children}
			</Typography>
		</Tag>
	);
}

type NewLabelProps = {
	dataSource: string[] | DataSourceItemObject[];
	onLabelCreate: (value: string) => void;
	showInput: boolean;
	setShowInput: (vis: boolean) => void;
	value: string;
	onChange: (e: any) => void;
	onBlur?: (e: React.FocusEvent) => void;
	setRenamePrefix?: boolean;
	size?: "medium" | "large";
	dotted?: boolean;
	archived?: boolean;
	title?: string;
};

export function NewLabel({
	dataSource,
	onLabelCreate,
	showInput,
	setShowInput,
	value,
	onChange,
	onBlur,
	setRenamePrefix,
	size = "medium",
	dotted,
	archived,
	title = "New tag",
}: NewLabelProps): JSX.Element {
	const autoCompleteRef = useRef<any>(null);
	const currentOptions = useMemo(() => {
		let options = dataSource;
		if (dataSource.length && typeof dataSource[0] === "string") {
			options = (dataSource as string[]).map((data) => {
				return {
					value: data,
					text: data,
				};
			});
		}
		return (options as DataSourceItemObject[]).sort((a, b) =>
			a.text.localeCompare(b.text)
		);
	}, [dataSource]);

	// Options for the autocomplete input
	const [options, setOptions] =
		useState<DataSourceItemObject[]>(currentOptions);
	// Visibility of the autocomplete dropdown
	const [dropdownVis, setDropdownVis] = useState<boolean>(false);
	// Whether to display the line above the last option
	const [showLine, setShowline] = useState<boolean>(false);

	// Display results that match the input along with a create new option
	const handleSearchOrFocus = (inputValue: string) => {
		if (!inputValue) {
			setOptions(currentOptions);
			setDropdownVis(!!currentOptions.length);
			setShowline(false);
			return;
		}
		const hasValue = currentOptions.find(
			(option) =>
				inputValue.trim().toLowerCase() === option.value.toLowerCase()
		);
		const filteredRes = currentOptions?.filter(
			(option) =>
				option.value
					.toLowerCase()
					.includes(inputValue.trim().toLowerCase()) &&
				hasValue?.value.toLowerCase() !== option.value.toLowerCase()
		);

		const textPrefix = setRenamePrefix ? "Rename:" : "New tag:";

		setOptions([
			...(!hasValue
				? [
						{
							text: `${textPrefix} "${inputValue}"`,
							value: `${inputValue}`,
						},
				  ]
				: [hasValue]),
			...filteredRes,
		]);
		setDropdownVis(true);
		setShowline(!hasValue);
	};

	const handleOnBlurAutoComplete = () => {
		if (!value) {
			setShowInput(false);
		} else {
			onLabelCreate(value);
			if (autoCompleteRef.current) {
				autoCompleteRef.current.focus();
			}
		}
	};

	const TAG_CHAR_LIMIT = 50;

	return (
		<>
			{showInput ? (
				<AutoComplete
					maxLength={TAG_CHAR_LIMIT}
					className={styles.genemodNewLabelContainer}
					value={value}
					onChange={onChange}
					dataSource={options}
					autoFocus
					allowClear
					ref={autoCompleteRef}
					defaultActiveFirstOption
					getPopupContainer={(trigger) =>
						trigger.parentNode as HTMLElement
					}
					dropdownClassName={classNames(styles.genemodLabelDropdown, {
						[styles.newLabelWithLine]: showLine,
					})}
					dropdownMatchSelectWidth={false}
					open={dropdownVis}
					onSearch={handleSearchOrFocus}
					onSelect={(selectedValue: any) => {
						onLabelCreate(selectedValue.toString());
						setDropdownVis(false);
						setShowline(false);
						handleSearchOrFocus("");
						setOptions(
							currentOptions.filter(
								(o) => o.value !== selectedValue.toString()
							)
						);
					}}
					onFocus={() => handleSearchOrFocus(value)}
					onBlur={handleOnBlurAutoComplete}
					dropdownAlign={{
						overflow: {
							adjustX: true,
							adjustY: false,
						},
					}}
				>
					<Input onBlur={handleOnBlurAutoComplete} />
				</AutoComplete>
			) : (
				<div
					className={classNames(styles.newLabel, {
						[styles.dotted]: !!dotted,
						[styles.large]: size === "large",
						[styles.archived]: archived,
					})}
					onClick={() => {
						if (!archived) {
							setShowInput(true);
						}
					}}
				>
					<GenemodIcon
						name="plus"
						size={size === "large" ? "default" : "small"}
						color={archived ? "text-disabled" : "text-secondary-v2"}
					/>
					<Typography
						variant={size === "large" ? "regular" : "footnote"}
						color={archived ? "text-disabled" : "text-secondary-v2"}
					>
						{title}
					</Typography>
				</div>
			)}
		</>
	);
}
