import React from "react";
import { Pagination, PaginationProps } from "antdv5";
import styles from "./PageNumbers.module.scss";

type PageNumbersProps = PaginationProps & {
	className?: string;
};

export const PAGE_SIZES = [25, 50, 100, 200];

// The default page size is 25, if you pass 75 as the total amout of elements, you will have 3 pages
function PageNumbersV2(props: PageNumbersProps): JSX.Element {
	return (
		<div className={styles.pageNumbersContainer}>
			<Pagination
				pageSizeOptions={PAGE_SIZES}
				defaultPageSize={PAGE_SIZES[0]}
				{...props}
			/>
		</div>
	);
}

export default PageNumbersV2;
