import {
	Button,
	GenemodIcon,
	GenemodLink,
	Popover,
	Typography,
} from "@components";
import useCurrentFeatureRestriction from "@helpers/Hooks/useCurrentFeatureRestrictionHook";
import React, { useEffect, useState } from "react";
import sidebarStyles from "../index.module.scss";
import styles from "./index.module.scss";

/**
 * Main menu sidebar button that is only visible to free team users.
 */
export default function FreePlan(): JSX.Element {
	const [visible, setVisible] = useState(false);
	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	const isFreePlan = featureRestrictionData?.plan_name === "free";

	useEffect(() => {
		// Refetch feature restriction data
		if (visible) {
			fetchFeatureRestriction();
		}
	}, [visible]);

	if (!isFreePlan) return <></>;
	return (
		<Popover
			visible={visible}
			onVisibleChange={setVisible}
			content={<FreePlanInfo setVisible={setVisible} />}
			placement="right"
			trigger="click"
			overlayClassName={styles.overlay}
		>
			<div className="sidebar-btn">
				<div className="btn-icon">
					<GenemodIcon
						name="stars"
						color="text-on-color"
						size="large"
					/>
				</div>
				<div className={sidebarStyles.sidebarBtnLabel}>Free plan</div>
			</div>
		</Popover>
	);
}

type FreePlanInfoProps = React.ComponentPropsWithoutRef<"div"> & {
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

function FreePlanInfo({ setVisible }: FreePlanInfoProps): JSX.Element {
	return (
		<div className={styles.content}>
			<Typography variant="label">
				Your workspace is currently on the <b>Free plan.</b>
				<GenemodLink
					to="ACCOUNT_CHANGE_PLAN"
					style={{ display: "inline-block" }}
					onClick={() => setVisible(false)}
				>
					<Button type="link">See plans</Button>
				</GenemodLink>
			</Typography>
		</div>
	);
}
