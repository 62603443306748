/* eslint-disable prefer-const */
import React, { FunctionComponent, useState } from "react";
import styles from "./GenemodIcon.module.scss";
import {
	DemoWrapper,
	DemoSection,
	Demo,
	SVG,
	Input,
	Checkbox,
	LayerSystemContainer,
} from "@components";
import classNames from "classnames";
import {
	BusinessIcons,
	CommunicationIcons,
	DirectionalIcons,
	FileTypesIcons,
	GenemodIcons,
	GeneralIcons,
	IconName,
	IconNames,
	InternetTechnologyIcons,
	ScienceIcons,
	TextEditorIcons,
	TimeIcons,
} from "./GenemodIconRegistry";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import Tooltip from "../Tooltip";
import Typography from "../Typography/Typography";
import { GenemodDot } from "@components";

// Fetch SVG files from the icon folder
const CONTEXT = require.context("../../icons", true, /\.svg$/);

// Find the svg in the icons obj using the name prop
const findSVG = (name: string) => {
	return CONTEXT("common/icons/" + name + ".svg");
};

export type GenemodIconProps = React.HTMLAttributes<HTMLDivElement> & {
	/** Size of the icon. Default = 18px; Small=12px; Large = 24px; XLarge = 32px;*/
	size?:
		| "default"
		| "large"
		| "small"
		| "medium"
		| "XLarge"
		| "card"
		| "smallCard";
	/** Name of the icon. See components page for names */
	name?: IconName;
	/** If true, gives the icon a unique key prop so that it re-renders and re-processes the props*/
	forceRerender?: boolean;
	key?: any;

	/** Stylings and Additional className */
	className?: string;
	style?: React.CSSProperties;

	/** Styling for SVG icon */
	iconClassName?: string;

	/** If true, display the cursor as default when hover on top */
	decorative?: boolean;
	/** If true, display the cursor as not-allowed when hover on top */
	disabled?: boolean;

	/** Fill of the icon. Applied to all "fill=color" attributes. Use css for * more specific selectors. Only applied on load */
	fill?: Color;
	/** Stroke of the icon */
	stroke?: Color;
	/** A different color of the icon, defined in css */
	color?: Color;
	/** If true, display a background when hover on top */
	hoverEffect?: boolean;
	/** If true, display a background with box-shadow when hover (used in RackCard & CategoryCard) */
	shadowHover?: boolean;
	/** If true, display a notification indicator(red-dot) on the top right */
	showNotification?: boolean;
	/** Fill color for the notification passed as a color string */
	notificationColor?: Color;
	/** Called if the icon is defined and when it gets clicked */
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	/** If true, use lightMode for Sort component.  */
	lightmode?: boolean;
	/** If true, apply display: contents to the inenr div */
	hideInnerDiv?: boolean;
	/**
	 * Svg component to passthrough rather than using the registered svgs
	 */
	children?: JSX.Element;
	dataCy?: string;
};

const GenemodIcon: FunctionComponent<GenemodIconProps> = (props) => {
	const {
		size = "default",
		name,
		color = "",
		fill = "",
		stroke,
		forceRerender,
		key,
		className,
		style,
		disabled = false,
		shadowHover = false,
		lightmode = false,
		hideInnerDiv = false,
		children,
		dataCy,
	} = props;

	// remove props not found on divs so we do not get console warnings
	const {
		iconClassName,
		showNotification = false,
		notificationColor = "accent-strong",
		hoverEffect = false,
		decorative = false,
		...divProps
	} = props;

	const containerClasses = classNames(
		styles.svgContainer,
		`iconName_${name}`,
		"genemod-icon",
		name && { [styles[name]]: name },
		{ "genemod-lightmode": lightmode },
		className || "",
		{
			[styles.svgContainerXLarge]: size === "XLarge",
			[styles.svgContainerLarge]: size === "large",
			[styles.svgContainerSmall]: size === "small",
			[styles.svgContainerMedium]: size === "medium",
			[styles.svgContainerCard]: size === "card",
			[styles.svgContainerSmallCard]: size === "smallCard",
			[styles.decorative]: decorative,
			[styles.disabled]: disabled,
			[styles.interactive]: !decorative && !disabled,
			[styles.hoverEffect]: hoverEffect,
			[styles.shadowHover]: shadowHover,
		}
	);

	//Only override default svg fill if props is passed
	const fillProp = fill && { fill: ColorCssVarMap[fill] };
	const svgPath = name && findSVG(name);

	if (!children && !svgPath) {
		return <></>;
	}

	const svgComponent = children || (
		<SVG
			className={iconClassName}
			src={svgPath}
			color={
				(color && ColorCssVarMap[color]) ||
				(fill && ColorCssVarMap[fill])
			}
			{...fillProp}
			stroke={stroke && ColorCssVarMap[stroke]}
		/>
	);
	return (
		<div
			{...divProps}
			className={containerClasses}
			style={{ position: "relative", ...style }}
			key={forceRerender ? (name || "") + new Date().getTime() : key}
			data-cy={dataCy}
		>
			<div
				data-is-icon={true}
				style={hideInnerDiv ? { display: "contents" } : {}}
			>
				{showNotification && (
					<GenemodDot
						wrapperProps={{ className: styles.notification }}
						size={6.5}
						color={ColorCssVarMap[notificationColor]}
					/>
				)}
				{svgComponent}
			</div>
		</div>
	);
};

export default GenemodIcon;

// Icon Demo
export function GENEMOD_ICON_DEMO(): JSX.Element {
	// const { system, freezer, account, ["text-editor"]: editor } = ICONS;
	const [search, setSearch] = useState("");
	const [showIndicator, setShowIndicator] = useState(false);
	return (
		<LayerSystemContainer>
			<DemoWrapper>
				<DemoSection>
					<Demo
						title="Access icons by name"
						description={
							<div>
								Use the <strong>Icon</strong> component to
								access any of these icons without having to
								explicitly import them.
							</div>
						}
					>
						<Input
							label="Enter an icon's name"
							value={search}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							): void => setSearch(e.target.value)}
						/>
						<Checkbox
							value={showIndicator}
							onChange={(v) => setShowIndicator(!!v)}
						>
							Show Indicator
						</Checkbox>
						<div className={styles.iconDemoResult}>
							<span>Exact Match:</span>
							{GenemodIcons.includes(search as IconName) && (
								<GenemodIcon
									name={search as IconName}
									showNotification={showIndicator}
								/>
							)}
						</div>
						<div className={styles.iconDemoResult}>
							<span>Partial Match:</span>
							{search &&
								GenemodIcons.filter((name) => name !== search)
									.filter((name) => name.includes(search))
									.map((name, index) => (
										<Tooltip
											key={`${name}_${index}`}
											overlay={
												<Typography color="text-inverse">
													{name}
												</Typography>
											}
										>
											<GenemodIcon
												name={name as IconName}
												showNotification={showIndicator}
											/>
										</Tooltip>
									))}
						</div>
					</Demo>
					<Demo title="General Icons">
						<div className={styles.iconDemoWrapper}>
							{GeneralIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Bussines Icons">
						<div className={styles.iconDemoWrapper}>
							{BusinessIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Time Icons">
						<div className={styles.iconDemoWrapper}>
							{TimeIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Comunication Icons">
						<div className={styles.iconDemoWrapper}>
							{CommunicationIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Internet Technology Icons">
						<div className={styles.iconDemoWrapper}>
							{InternetTechnologyIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Science Icons">
						<div className={styles.iconDemoWrapper}>
							{ScienceIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Text Editor Icons">
						<div className={styles.iconDemoWrapper}>
							{TextEditorIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Directional Icons">
						<div className={styles.iconDemoWrapper}>
							{DirectionalIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="File Types">
						<div className={styles.iconDemoWrapper}>
							{FileTypesIcons.map((iconName) => (
								<div className={styles.iconDemo} key={iconName}>
									<GenemodIcon name={iconName as IconName} />
									<span>{iconName}</span>
								</div>
							))}
						</div>
					</Demo>
					<Demo title="Unorganized or duplicated icons">
						<div className={styles.iconDemoWrapper}>
							{IconNames.map((iconName) => {
								return (
									<div
										className={styles.iconDemo}
										key={iconName}
									>
										<GenemodIcon
											name={iconName as IconName}
										/>
										<span>{iconName}</span>
									</div>
								);
							})}
						</div>
					</Demo>
					<Demo title="Unregistered icons">
						<div className={styles.iconDemoWrapper}>
							{CONTEXT.keys()
								.filter((name) => name.startsWith("./"))
								.map((name) => name.replace("./", ""))
								.map((name) => name.replace(".svg", ""))
								.filter(
									(name) =>
										!GenemodIcons.includes(name as IconName)
								)
								.map((iconName) => {
									return (
										<div
											className={styles.iconDemo}
											key={iconName}
										>
											<GenemodIcon
												name={iconName as IconName}
											/>
											<span>{iconName}</span>
										</div>
									);
								})}
						</div>
					</Demo>
					<Demo
						title="Hover Effects"
						description={
							<div>
								Use the <strong>decorative</strong> prop to
								change the cursor type. ex. decorative = true
								(cursor: default)
								<br />
								Use the <strong>hoverEffect</strong> prop to
								change whether the icon have effect on hover ex.
								hoverEffect = true
								<br />
								Use the <strong>shadowHover</strong> prop to
								change whether the icon have a shadow effect on
								hover
								<br />
							</div>
						}
					>
						<div className={styles.iconDemoResult}>
							decorative{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
								decorative
							/>
						</div>
						<div className={styles.iconDemoResult}>
							size=&quot;default&quot;{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
							/>
						</div>
						<div className={styles.iconDemoResult}>
							hoverEffect, size=&quot;large&quot;{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
								hoverEffect
								size="large"
							/>
						</div>
						<div className={styles.iconDemoResult}>
							shadowHover, size=&quot;large&quot;{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
								hoverEffect
								shadowHover
								size="large"
							/>
						</div>
					</Demo>
					<Demo
						title="Color of the icon"
						description={
							<div>
								Use the <strong>fill/stroke</strong> prop to
								apply specific colors to icons.
								<br />
								The default filled color is set to
								text-secondary
								<br />
							</div>
						}
					></Demo>
					<Demo
						title="Icon size"
						description={
							<div>
								Use the <strong>size</strong> prop to change the
								size of the icons. Default is 18px. Large =
								24px. XLarge = 32px.
							</div>
						}
					>
						<div className={styles.iconDemoResult}>
							Default (18px):{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
							/>
						</div>
						<div className={styles.iconDemoResult}>
							Large (24px):{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
								size="large"
							/>
						</div>
						<div className={styles.iconDemoResult}>
							XLarge (32px):{" "}
							<GenemodIcon
								name="meatballs"
								style={{ marginLeft: "10px" }}
								size="XLarge"
							/>
						</div>
					</Demo>
				</DemoSection>
			</DemoWrapper>
		</LayerSystemContainer>
	);
}
