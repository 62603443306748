import React, { useEffect } from "react";
import { FIELD_WIDTHS, FIELD_WIDTH_STYLES } from "@common/types";
import {
	TextArea,
	Input,
	Typography,
	CustomDatePicker,
	CustomDatePickerV2,
	Tooltip,
} from "@components";
import styles from "./styles.module.scss";
import InputV3 from "@common/components/InputV3/InputV3";
import dayjs from "dayjs";
import { useInputValidation } from "@helpers/InputHelper";

export type FormItemWithTypeProps = {
	id: string;
	label: string;
	value: string;
	onChange: (
		value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => any;
	type: string;
	required: boolean;
	gutterBottom: boolean;
	autoFocus: boolean;
	prefix: string;
	suffix: string;
	validators: [];
	disabled: boolean;
	maxLength: number;
	children: any;
	style: any;
	width: keyof typeof FIELD_WIDTH_STYLES;
	placeholder: string;
	renderCustomContent?: () => JSX.Element;
	submitOnEnter?: () => void;
};

/**
 * The component of FormItem which will be composed in formSection.
 *
 * @param {*} props
 * @param {String} id -content id
 * @param {String} label -text label which will be title of the content
 * @param {String} value -value of contents
 * @param {Function} onChange -function called when content is changed
 * @param {String} props.type  -type of contents (custom: props.children, TextArea: TextArea, Otherwise: Input)
 * @param {Boolean} required -whether this content is required or not (if true * mark will be shown)
 * @param {Boolean} gutterBottom -whether to make bottom margin or not
 */
export function FormItemWithType(props: FormItemWithTypeProps) {
	const {
		id,
		label,
		value,
		onChange,
		autoFocus,
		prefix,
		suffix,
		validators,
		disabled,
		maxLength,
		children,
		placeholder,
		renderCustomContent,
		submitOnEnter,
	}: FormItemWithTypeProps = props;
	let type = props.type;
	const [isValid, showError] = useInputValidation(value, validators);
	const getContentType = () => {
		if (type) {
			type = type.toLowerCase();
		}
		if (type === "textarea") {
			return (
				<TextArea
					autoFocus={autoFocus}
					id={id}
					onChange={onChange}
					value={value}
					style={{ minHeight: 54 }}
					placeholder={placeholder}
					disabled={disabled}
					dataCy={`new-item-${
						label ? label.toLowerCase().replace(/\s/g, "-") : id
					}`}
					containerClassname={styles.textAreaContainer}
				/>
			);
		}
		if (type === "custom" && renderCustomContent) {
			return renderCustomContent();
		}
		if (type === "datepicker") {
			return (
				<CustomDatePickerV2
					id={id}
					value={value ? dayjs(value) : dayjs()}
					onChange={(val) => {
						if (val) {
							onChange({
								target: {
									id,
									value: val.format("MM/DD/YYYY"),
								},
							} as any);
						}
					}}
					wrapperClassName={styles.datePicker}
				/>
			);
		}

		useEffect(() => {
			if (autoFocus) {
				const input = document.getElementById(id);
				setTimeout(() => {
					input?.focus();
				}, 500);
			}
		}, [autoFocus]);

		return (
			<Tooltip placement="topLeft" title={showError} open={!isValid}>
				<InputV3
					id={id}
					onChange={onChange}
					value={value}
					prefix={prefix}
					suffix={suffix}
					className={styles.input}
					disabled={disabled}
					maxLength={maxLength || 200}
					dataCy={`new-item-${
						label ? label.toLowerCase().replace(/\s/g, "-") : id
					}`}
					onPressEnter={() => submitOnEnter?.()}
				/>
			</Tooltip>
		);
	};
	return <FormItem {...props}>{getContentType()}</FormItem>;
}

export type FormItemProps = {
	label: string;
	required?: boolean;
	gutterBottom?: boolean;
	children: React.ReactNode;
	style?: React.CSSProperties;
	width?: keyof typeof FIELD_WIDTH_STYLES;
};

export function FormItem({
	label,
	required = false,
	gutterBottom = false,
	children,
	style = {},
	width = FIELD_WIDTHS.FULL,
}: FormItemProps) {
	const widthStyles = FIELD_WIDTH_STYLES[width];

	return (
		<div
			className={styles.formItem}
			style={{
				marginBottom: gutterBottom ? "32px" : "0px",
				...style,
				...widthStyles,
			}}
		>
			<div className="form-label">
				<Typography
					variant="body"
					medium
					color="text-primary-v2"
					style={{ marginBottom: "8px" }}
				>
					{label}
					{required && (
						<span style={{ color: "var(--red)" }}> *</span>
					)}
				</Typography>
			</div>
			<div className={styles.formInput}>{children}</div>
		</div>
	);
}
