// Router for Genemod Application
import { setAxiosHeader } from "@API";
import asyncComponent from "@common/AsyncFunc";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from "@common/helpers/Hooks/UseRouterDom";
import { WorkspaceId } from "@common/types";
import { Layout, LoadingSpinner, Notification, Sidebar } from "@components";
import GlobalModals from "@containers/App/GlobalModals";
import GlobalNewFeaturesPopOver from "@containers/App/GlobalNewFeaturesPopOver";
import AccountDeactivatedScreen from "@containers/Auth/components/AccountDeactivatedScreen/AccountDeactivatedScreen";
import AccountRestrictedScreen from "@containers/Auth/components/AccountRestrictedScreen";
import { OfficeProvider } from "@containers/Office/context";
import { useLocalStorage } from "@helpers/Hooks/UseLocalStorageHook";
import { usePreferenceInitializer } from "@helpers/Hooks/UsePreferencesHook";
import useQuickAccessItemsHook from "@helpers/Hooks/useQuickAccessItemsHook";
import {
	useGetOrganizationsQuery,
	useGetPersonalWorkspacesQuery,
} from "@redux/team/TeamApi";
import qs from "query-string";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from "react";
import { useSearchParamAndSet } from "./common/helpers/URLParams";

const APP_PATH = "/app";
const WORKSPACE_IDENTIFIER = ":workspace_uuid";

export type GenemodTool =
	| "DASHBOARD"
	| "PROJECT_MANAGEMENT"
	| "INVENTORY"
	| "OFFICE"
	| "DNA"
	| "ACCOUNT_SETTINGS"
	| "NOT_FOUND"
	| "EQUIPMENTS"
	| "FRAMEWORK";

export const APP_ROUTES: Record<
	GenemodTool,
	{ path: string; component: React.ComponentType<any> }
> = {
	DASHBOARD: {
		path: "dashboard",
		component: React.lazy(() => import("./containers/Dashboard/index")),
	},
	PROJECT_MANAGEMENT: {
		path: "projects",
		component: React.lazy(
			() => import("./containers/ProjectManagement/index")
		),
	},
	INVENTORY: {
		path: "freezer",
		component: React.lazy(() => import("./containers/Freezer/index")),
	},
	OFFICE: {
		path: "office",
		component: React.lazy(() => import("./containers/Office/index")),
	},
	DNA: {
		path: "sequence-editor",
		component: React.lazy(() => import("./containers/Dna/index")),
	},
	ACCOUNT_SETTINGS: {
		path: "account-settings",
		component: React.lazy(
			() => import("./containers/AccountSettings/index")
		),
	},
	EQUIPMENTS: {
		path: "core-facility",
		component: React.lazy(() => import("./containers/Facility/index")),
	},
	FRAMEWORK: {
		path: "framework",
		component: React.lazy(() => import("./containers/Framework/index")),
	},
	NOT_FOUND: {
		path: "404",
		component: React.lazy(() => import("./containers/PageNotFound/index")),
	},
};

function AppRouter(): JSX.Element {
	const {
		data: workspaces,
		isFetching: isFetchingWorkspaces,
		isError: isErrorWorkspaces,
	} = useGetPersonalWorkspacesQuery();
	const {
		data: organizations,
		isFetching: isFetchingOrganizations,
		isError: isErrorOrgs,
	} = useGetOrganizationsQuery();
	usePreferenceInitializer();
	// Extend this logic when we support multi org
	const organization = organizations?.[0];
	const isFetching = isFetchingWorkspaces || isFetchingOrganizations;
	const isError = isErrorWorkspaces || isErrorOrgs;
	const noOrgsOrWorkspaces =
		organizations?.length === 0 || workspaces?.length === 0;
	const isOrgUserDeactivated = organization?.is_user_active === false;

	useEffect(() => {
		if (organization) {
			// Update axios headers
			setAxiosHeader("Genemod-OrganizationId", organization?.id);
		}
	}, [organization]);

	useEffect(() => {
		if (isError) {
			Notification.error({
				message: "Error loading workspaces. Please refresh the page.",
			});
		}
	}, [isError]);

	return (
		<div style={{ height: "100%", width: "100%" }}>
			{(() => {
				if (noOrgsOrWorkspaces) {
					return <AccountRestrictedScreen type="NO_WORKSPACES" />;
				}
				if (isOrgUserDeactivated) {
					return <AccountDeactivatedScreen />;
				}
				if (isFetching) {
					return <LoadingSpinner loading centered />;
				}
				return (
					<React.Suspense
						fallback={
							<>
								<div>
									<div style={{ maxWidth: "54px" }}>
										<Sidebar />
									</div>
									<div>
										<LoadingSpinner
											size="large"
											loading
											centered
										/>
									</div>
								</div>
							</>
						}
					>
						<Switch>
							<Route path={APP_PATH}>
								<Switch>
									<Route
										path={[
											APP_PATH,
											WORKSPACE_IDENTIFIER,
										].join("/")}
										render={() => <OrganizationRouter />}
									/>
									<Route
										render={() => <AppendWorkspaceId />}
									/>
								</Switch>
							</Route>
						</Switch>
					</React.Suspense>
				);
			})()}
		</div>
	);
}

/**
 * No workspace was included in the url, useWorkspaceUrlHeaderAndLocalStorage will add it.
 */
function AppendWorkspaceId() {
	useWorkspaceUrlHeaderAndLocalStorage();
	return <LoadingSpinner loading />;
}

type OrganizationRouterContextAPI = {
	/**
	 * Prefixes the route with the app's base path. Replaces the variables
	 * in the path (workspace_uuid) with the current values.
	 */
	appendBaseUrl: (route: string) => string;
	/**
	 * Prefixes the route with the base app path. Does not fill in path variables.
	 */
	appendBasePath: (route: string) => string;
	/**
	 * Returns the route for a tool within the Genemod app
	 */
	getToolRoute: (tool: GenemodTool) => string;
	/**
	 * Returns a Redirect component with the base url attached.
	 */
	getRedirect: (to: string) => React.ReactNode | null;
	/**
	 * Prefixes the route with the app's base path. Replaces the variables
	 * in the path (workspace_uuid) with the current values.
	 */
	appendBaseUrlAndParams: (
		route: string,
		params?: Record<string, any>
	) => string;
};

export const OrganizationRouterContext =
	createContext<OrganizationRouterContextAPI>({
		appendBaseUrl: () => "",
		appendBasePath: () => "",
		getToolRoute: () => "",
		getRedirect: () => null,
		appendBaseUrlAndParams: () => "",
	});

export function useOrganizationRouter(): OrganizationRouterContextAPI {
	return useContext(OrganizationRouterContext);
}

export function AppRoute(
	props: React.ComponentProps<typeof Route>
): JSX.Element {
	const { path } = props;
	const orgRouter = useOrganizationRouter();

	let newPaths = path;
	if (typeof path === "string") {
		newPaths = orgRouter.appendBasePath(path);
	} else if (Array.isArray(path)) {
		newPaths = path.map((p) => orgRouter.appendBasePath(p));
	}
	return (
		<Route {...props} path={newPaths}>
			{props.children}
		</Route>
	);
}

/**
 * Externalized for use in GenemodLink demo
 */
export const appendSearchString = (
	path: string,
	params?: Record<string, any>
) => {
	let paramString = qs.stringify(params || {});
	if (paramString.length > 0) {
		paramString = "?" + paramString;
	}
	return path + paramString;
};

/**
 * Wrapper component that integrates the URL organization_id
 * with axios
 */
function OrganizationRouter() {
	const currentWorkspaceUUID = useWorkspaceUrlHeaderAndLocalStorage();

	const appendBaseUrl = useCallback(
		(route: string) => {
			return [APP_PATH, currentWorkspaceUUID, route].join("/");
		},
		[currentWorkspaceUUID]
	);

	const appendBaseUrlAndParams = useCallback(
		(route: string, params?: Record<string, any>) => {
			return appendBaseUrl(appendSearchString(route, params));
		},
		[currentWorkspaceUUID, appendBaseUrl]
	);

	const appendBasePath = useCallback((route: string) => {
		// Only need to calculate once since the base path is a constant
		return [APP_PATH, WORKSPACE_IDENTIFIER, route].join("/");
	}, []);

	const getToolRoute = useCallback(
		(tool: GenemodTool) => {
			return appendBaseUrl(APP_ROUTES[tool].path);
		},
		[currentWorkspaceUUID, appendBaseUrl]
	);

	const getRedirect = useCallback(
		(to: string) => {
			if (!currentWorkspaceUUID) return null;
			return <Redirect to={appendBaseUrl(to)} />;
		},
		[currentWorkspaceUUID]
	);

	return (
		<OrganizationRouterContext.Provider
			value={{
				appendBaseUrl,
				appendBasePath,
				getToolRoute,
				getRedirect,
				appendBaseUrlAndParams,
			}}
		>
			<Layout>
				<OfficeProvider>
					<AppRoutes />
				</OfficeProvider>
			</Layout>
			<GlobalModals />
			<GlobalNewFeaturesPopOver />
		</OrganizationRouterContext.Provider>
	);
}

/**
 * Routes for each tool within the Genemod application
 */
function AppRoutes() {
	const { path: basePath } = useRouteMatch();
	const { getRedirect } = useOrganizationRouter();

	/** Add Hook to record a quick access item. */
	useQuickAccessItemsHook();

	return (
		<Switch>
			{Object.values(APP_ROUTES).map((route) => {
				const { path, component } = route;
				return (
					<Route
						key={path}
						path={path ? `${basePath}/${path}` : basePath}
						component={component}
					/>
				);
			})}
			{getRedirect(APP_ROUTES.DASHBOARD.path)}
		</Switch>
	);
}

/**
 * Hook to be used exclusively in AppendWorkspaceId and OrganizationRouter components to
 * handle setting up workspace uuid in the url, header, and local storage.
 *
 * NOTE: use useCurrentWorkspaceUuid instead!
 */
const useWorkspaceUrlHeaderAndLocalStorage = (
	isUUIDAvailable = true
): WorkspaceId => {
	// ~~~~~~~~~~~~~~~~~~~~~~~~
	// ~~~~~SETUP VARIABLES~~~~
	// ~~~~~~~~~~~~~~~~~~~~~~~~
	const history = useHistory();
	const { data: workspaces } = useGetPersonalWorkspacesQuery();
	const match = useRouteMatch<{ workspace_uuid: string }>();
	const urlWorkspaceUUID = match.params["workspace_uuid"] as
		| WorkspaceId
		| undefined;
	const [emailRedirectPath] = useSearchParamAndSet("emailRedirectPath");
	const [localStorageWorkspaceUUID, _setLocalStorageWorkspaceUUID] =
		useLocalStorage<WorkspaceId | null>("LAST_USED_WORKSPACE_ID", null);

	// Update local storage without triggering unnecessary rerenders
	const setLocalStorageWorkspaceUUID = (uuid: WorkspaceId) =>
		uuid !== localStorageWorkspaceUUID &&
		_setLocalStorageWorkspaceUUID(uuid);

	// Workspaces should be defined since we are verifying that in the AppRouter component
	if (workspaces === undefined) return "" as WorkspaceId;

	const firstWorkspaceUUIDInList = workspaces[0]?.id;

	// ~~~~~~~~~~~~~~~~~~~~~~~~
	// ~~~~~LOGIC FUNCTIONS~~~~
	// ~~~~~~~~~~~~~~~~~~~~~~~~

	// Checks if a given uuid is found in the users list of workspaces
	const isValidWorkspaceUUID = (
		uuid: WorkspaceId | undefined | null
	): uuid is WorkspaceId =>
		!!uuid && workspaces.some(({ id }) => id === uuid);

	// Uses url uuid to setup local storage and header workspace
	const updateWithUrlUUID = (): WorkspaceId | null => {
		if (!isValidWorkspaceUUID(urlWorkspaceUUID)) return null;
		setLocalStorageWorkspaceUUID(urlWorkspaceUUID);
		setAxiosHeader("Genemod-WorkspaceId", urlWorkspaceUUID);
		return urlWorkspaceUUID as WorkspaceId;
	};

	// Uses local storage uuid to setup url and header workspace
	const updateWithLocalStorageUUID = (): WorkspaceId | null => {
		if (!isValidWorkspaceUUID(localStorageWorkspaceUUID)) return null;
		isUUIDAvailable &&
			history.push(
				`${APP_PATH}/${localStorageWorkspaceUUID}${
					emailRedirectPath || ""
				}`
			);
		setAxiosHeader("Genemod-WorkspaceId", localStorageWorkspaceUUID);
		return localStorageWorkspaceUUID;
	};

	// Uses first workspace list uuid to setup local storage, url, and header workspace
	const updateWithWorkspaceListUUID = (): WorkspaceId => {
		setLocalStorageWorkspaceUUID(firstWorkspaceUUIDInList);
		isUUIDAvailable &&
			history.push(
				`${APP_PATH}/${firstWorkspaceUUIDInList}${
					emailRedirectPath || ""
				}`
			);
		setAxiosHeader("Genemod-WorkspaceId", firstWorkspaceUUIDInList);
		return firstWorkspaceUUIDInList;
	};

	// ~~~~~~~~~~~~~~~~~~~~~~~~
	// ~~~~~UPDATING LOGIC~~~~~
	// ~~~~~~~~~~~~~~~~~~~~~~~~

	// Run the update functions in order of precedence. If a function returns null that
	// means the corresponding uuid was invalid and we move on to the next function.
	return (
		updateWithUrlUUID() ||
		updateWithLocalStorageUUID() ||
		updateWithWorkspaceListUUID()
	);
};

export default AppRouter;
