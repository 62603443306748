import { Column, Row } from "@common/components/InteractiveGrid/GridTypes";
import { Item, SimpleItem } from "@common/types";
import { GenemodIcon, Modal, Typography, Notification } from "@components";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import { Link } from "@helpers/Hooks/UseRouterDom";
import { useSearchParamAndSet } from "@helpers/URLParams";
import {
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import { appSelector } from "@redux/store";
import cn from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import {
	areaBoundsIncludesCellId,
	BOX_GRID_ID,
	getCellId,
	getColumnLabel,
	getRowLabel,
} from "../../../data";
import { useBoxView, useSetCellId } from "../../BoxTableHooks";
import gridStyles from "../../TableGrid.module.scss";
import BoxTableCell from "../BoxTableCell/BoxTableCell";
import { HeaderCellActions, WithCellsMenu } from "../CellMenu/CellMenu";
import {
	CellSelectionAndDragMask,
	DragHandle,
	ShouldBlockMask,
} from "../CellSelectionAndDragMask/CellSelectionAndDragMask";
import {
	DeleteSelectedBoxTableCellsModal,
	PasteBoxCellsFromDragModal,
	PasteSelectedBoxTableCellsModal,
} from "../SelectedCellActionModals/SelectedCellActionModals";
import styles from "./InnerBoxTable.module.scss";
import { useSimpleItemsOnBoxQuery } from "@redux/inventory/Item";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "@common/helpers/Hooks/UseRouterDom";
import { useBulkItemsEditMutation } from "@redux/inventory/Item";

export const InnerBoxTable = (): JSX.Element => {
	const [returnUrl] = useSearchParamAndSet("return_url");

	const {
		freezer,
		box,
		viewOnly,
		refetchItems,
		isItemsUninitialized,
		setItemIdInUrl,
	} = useBoxView();
	const { box_id } = useParams<{
		box_id: string;
	}>();
	const { data: items = [] } = useSimpleItemsOnBoxQuery(
		Number(box_id) || skipToken
	);

	const setCellID = useSetCellId();
	const {
		draggingState,
		cutMode,
		itemsCut,
		isArchiving,
		selectedAreaBounds,
	} = useBoxTableFields(
		"draggingState",
		"cutMode",
		"itemsCut",
		"isArchiving",
		"selectedAreaBounds"
	);
	const { setIsFocused, setIsArchiving, setSelectedAreaBounds } =
		useBoxTableActions();

	const [bulkEditItems] = useBulkItemsEditMutation();

	const boxGridRef = useRef<HTMLDivElement>(null);

	const selectedItemIndex = appSelector(
		(state) => state.freezer.boxView.selectedItemIndex
	);
	const movingItems = appSelector(
		(state) => state.freezer.boxTable.movingItems
	);

	const num_rows =
		box && box.rows > -1 ? box?.rows : 8; /* Placeholder while loading */
	const num_cols =
		box && box.columns > -1
			? box?.columns
			: 8; /* Placeholder while loading */

	// Header
	const getColHeaders = () =>
		Array(num_cols)
			.fill(0)
			.map((_, index) => (
				<div
					className={cn(styles.colHeader)}
					key={"table-column" + index}
					style={{
						gridRowStart: 1,
						gridRowEnd: 2,
						gridColumnStart: index + 2,
						gridColumnEnd: index + 3,
					}}
				>
					<div>{getColumnLabel(index + 1, box?.axis_direction)}</div>
				</div>
			));

	// Row header
	const getRowHeaders = () =>
		Array(num_rows)
			.fill(0)
			.map((x, index) => (
				<div
					className={cn(styles.rowHeader)}
					key={"row-header-" + index}
					style={{
						gridRowStart: index + 2,
						gridRowEnd: index + 3,
						gridColumnStart: 1,
						gridColumnEnd: 2,
					}}
				>
					{getRowLabel(index + 1, box?.axis_direction)}
				</div>
			));

	// Actual table
	const cells = useMemo(() => {
		const newCells = [] as JSX.Element[];

		const itemsToCut = cutMode ? itemsCut : {};

		const itemLocationMapping = items.reduce((rowMapping, item) => {
			const row = item.location?.box_location?.row || 0;
			const column = item.location?.box_location?.column || 0;

			if (!rowMapping[row]) {
				rowMapping[row] = {};
			}

			rowMapping[row][column] = item;
			return rowMapping;
		}, {} as Record<number, Record<number, SimpleItem>>);

		for (let i = 0; i < num_rows; i++) {
			for (let j = 0; j < num_cols; j++) {
				const item = itemLocationMapping[i]?.[j];
				newCells.push(
					<BoxTableCell
						key={i + "-" + j}
						row={i as Row}
						column={j as Column}
						item={item}
						isInCutMode={itemsToCut[item?.id]}
						selectedItemIndex={selectedItemIndex}
						labelType={freezer?.label_type}
						num_rows={num_rows}
						viewOnly={viewOnly}
						dataCy={`item-table-${i + 1 + "-" + (j + 1)}`}
					/>
				);
			}
		}
		return newCells;
	}, [
		items,
		selectedItemIndex,
		freezer,
		num_rows,
		num_cols,
		viewOnly,
		cutMode,
		itemsCut,
	]);

	const isDragging = draggingState !== "NOT_DRAGGING";

	useSafeWindowEventListener("mousedown", (e) => {
		if (!boxGridRef.current) return;
		const rect = boxGridRef.current.getBoundingClientRect();

		const clickedInsideOfRect =
			e.clientX >= rect.left &&
			e.clientX <= rect.right &&
			e.clientY >= rect.top &&
			e.clientY <= rect.bottom;

		setIsFocused(clickedInsideOfRect);
	});

	const archiveSelected = () => {
		const numberOfItems = itemsSelected.length;
		if (itemsSelected.length === 0) return;
		bulkEditItems({ items: itemsSelected })
			.unwrap()
			.then((response) => {
				setItemIdInUrl(null);
				setIsArchiving(false);
				setSelectedAreaBounds([]);
				Notification.success({
					message: (
						<span>
							<b>{numberOfItems}</b>
							{` ${
								numberOfItems > 1 ? "items" : "item"
							} have been archived.`}
						</span>
					),
				});
			})
			.catch(() => {
				setIsArchiving(false);
				Notification.error({
					message:
						"Failed to archive items. Try again or contact us if it continues.",
				});
			});
	};

	const itemsSelected = useMemo(() => {
		return Object.values(
			selectedAreaBounds.reduce<Item[]>((accumulator, areaBounds) => {
				for (const item of items) {
					if (
						areaBoundsIncludesCellId(
							areaBounds,
							getCellId(item as any)
						)
					) {
						accumulator.push({
							...item,
							is_archived: true,
						} as any);
					}
				}
				return accumulator;
			}, [])
		);
	}, [selectedAreaBounds]);

	const getArchiveModalTitle = (): string => {
		return `Archive ${itemsSelected.length} ${
			itemsSelected.length > 1 ? "items" : "item"
		}`;
	};

	return (
		<div className={styles.headerAndBoxGrid}>
			<div
				className={cn(
					styles.sectionHeader,
					gridStyles.boxGridLeftHeader
				)}
			>
				{!isDragging && !freezer?.is_archived && <HeaderCellActions />}
			</div>
			<WithCellsMenu>
				<div
					id={BOX_GRID_ID}
					ref={boxGridRef}
					className={cn(
						styles.boxGrid,
						gridStyles.boxGridLeftContent
					)}
					style={{
						gridTemplateColumns: `24px repeat(${num_cols}, 1fr)`,
						gridTemplateRows: `24px repeat(${num_rows}, 1fr)`,
					}}
				>
					<div className={styles.dummy}></div>
					{getColHeaders()}
					{getRowHeaders()}
					{/*  adding genemod-lightmode class to utilize the same color for the lighmode since we want to use lightmode color for both theme */}
					<div
						className={cn(styles.tableContent, "genemod-lightmode")}
					>
						{cells}
						<CellSelectionAndDragMask />
						<DragHandle />
						<ShouldBlockMask />
					</div>
				</div>
			</WithCellsMenu>
			<DeleteSelectedBoxTableCellsModal />
			<PasteSelectedBoxTableCellsModal />
			<PasteBoxCellsFromDragModal />
			<ArchiveItemModal
				visible={isArchiving}
				onArchive={archiveSelected}
				onCancel={() => setIsArchiving(false)}
				title={getArchiveModalTitle()}
			/>
		</div>
	);
};
export const getLocation = (item: Item, isRow: boolean) =>
	isRow
		? item.location?.box_location?.row ?? -1
		: item.location?.box_location?.column ?? -1;

type ArchiveItemModalProps = {
	visible: boolean;
	onCancel: () => void;
	onArchive: () => void;
	title: string;
};
function ArchiveItemModal({
	visible,
	onCancel,
	onArchive,
	title,
}: ArchiveItemModalProps) {
	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			onOk={onArchive}
			title={
				<Typography variant="headline5" bold color="text-primary-v2">
					{title}
				</Typography>
			}
			okText="Archive"
			hideCancelButton
			dataCy="archive-modal"
			width={480}
		>
			<Typography variant="subheadline" color="text-secondary-v2">
				Archiving items prevents modifications, but you can access and
				restore them via any Box page or the Repository.
			</Typography>
			<Typography
				variant="subheadline"
				color="text-secondary-v2"
				style={{ marginTop: 8 }}
			>
				<strong>Note:</strong> Restored items won&apos;t be linked to
				their previous locations.
			</Typography>
		</Modal>
	);
}
