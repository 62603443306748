import {
	ButtonV2,
	CheckboxV2,
	DropdownV2,
	GenemodIcon,
	IconName,
	Notification,
	RadioButtonV2,
} from "@common/components";
import {
	ExperimentMaterialTemplate,
	ExperimentMaterialTemplateSchema,
} from "@common/types";
import { Typography } from "@components";
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import styles from "./MaterialTemplateCollapsable.module.scss";
import { DEFAULT_MATERIAL_TEMPALTE_LAYOUT } from "../constants";
import { MenuProps } from "antdv5";
import {
	useAddMaterialTemplateMutation,
	useDeleteMaterialTemplateMutation,
	usePatchMaterialTemplateMutation,
} from "@redux/ProjectManagement/PmApiSlice";
import DeleteModal from "@containers/Freezer/components/DeleteModal";
import MaterialTemplateCollapsableRow from "./MaterialTemplateCollapsableRow";
import InputV3 from "@common/components/InputV3/InputV3";
import classNames from "classnames";
import { column } from "mathjs";

const MaterialTemplateCollapsable = ({
	id,
	name,
	predefined,
	columns_order,
	schema,
	showOnly,
	checked,
	onRadioChange,
	experimentTemplate,
}: ExperimentMaterialTemplate & {
	showOnly?: boolean;
	checked?: boolean;
	onRadioChange?: (templateId: number) => void;
	experimentTemplate?: number;
}) => {
	const [addTemplate] = useAddMaterialTemplateMutation();
	const [deleteTemplate] = useDeleteMaterialTemplateMutation();
	const [patchTemplate] = usePatchMaterialTemplateMutation();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const [columns, setColumns] = useState(columns_order.split(","));
	const [renaming, setRenaming] = useState(false);
	const [templateName, setTemplateName] = useState(name);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const onKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			setRenaming(false);
			setTemplateName(name);
		}
	};

	useEffect(() => {
		if (renaming) {
			window.addEventListener("keydown", onKeyDown);
		} else {
			window.removeEventListener("keydown", onKeyDown);
		}

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [renaming]);

	const schemaObj = schema.reduce((acc, item) => {
		acc[item.label] = item;
		return acc;
	}, {} as Record<string, ExperimentMaterialTemplateSchema>);

	const renderMenuItem = (
		label: string,
		icon: IconName,
		remove?: boolean
	) => {
		return (
			<div className={styles.menuItem}>
				<GenemodIcon
					name={icon}
					color={remove ? "danger" : "text-tertiary-v2"}
				/>
				<Typography color={remove ? "danger" : "text-tertiary-v2"}>
					{label}
				</Typography>
			</div>
		);
	};

	const getMenuItems = () => {
		const items: MenuProps["items"] = [
			{
				key: "duplicate",
				label: renderMenuItem("Duplicate", "duplicate"),
			},
		];
		if (!predefined) {
			items.push(
				{ type: "divider" },
				{
					key: "delete",
					label: renderMenuItem(
						"Delete template",
						"trash-outlined",
						true
					),
				}
			);
		}
		return items;
	};

	const renderActionButtons = () => {
		if (!renaming) {
			return (
				<ButtonV2
					style={{ marginLeft: "auto" }}
					type="text"
					onClick={() => {
						setRenaming(true);
					}}
				>
					Rename
				</ButtonV2>
			);
		} else {
			return (
				<div className={styles.editing}>
					<ButtonV2
						icon="swoosh-check"
						type="text"
						onClick={() => {
							patchTemplate({
								id,
								name: templateName,
							}).finally(() => {
								setRenaming(false);
							});
						}}
					>
						Save
					</ButtonV2>
					<ButtonV2
						type="text"
						onClick={() => {
							setRenaming(false);
						}}
					>
						Cancel
					</ButtonV2>
				</div>
			);
		}
	};

	useEffect(() => {
		setColumns(columns_order.split(","));
	}, [columns_order]);

	return (
		<div className={styles.wrapper}>
			<div
				className={classNames(styles.main, {
					[styles.mainOpen]: !collapsed,
				})}
				onClick={() => setCollapsed((prev) => !prev)}
			>
				<GenemodIcon
					name={"chevron-right"}
					className={classNames(styles.chevron, {
						[styles.chevronOpen]: !collapsed,
					})}
				/>
				{renaming ? (
					<InputV3
						autoFocus
						value={templateName}
						onChange={(e) => setTemplateName(e.target.value)}
					/>
				) : (
					<Typography variant="headline5">{name}</Typography>
				)}
				{predefined && (
					<div className={styles.premade}>
						<Typography variant="footnote">Premade</Typography>
					</div>
				)}
				{experimentTemplate === id && (
					<Typography
						style={{ fontStyle: "italic" }}
						color="text-tertiary-v2"
					>
						Current
					</Typography>
				)}
				{!showOnly ? (
					<>
						<div
							onClick={(e) => e.stopPropagation()}
							style={
								!predefined ? { marginLeft: "auto" } : undefined
							}
						>
							{!predefined && renderActionButtons()}
						</div>
						<span
							onClick={(e) => e.stopPropagation()}
							style={
								predefined ? { marginLeft: "auto" } : undefined
							}
						>
							<DropdownV2
								menu={{
									items: getMenuItems(),
									onClick: (e) => {
										if (e.key === "delete") {
											setShowDeleteModal(true);
										}
										if (e.key === "duplicate") {
											Notification.loading({
												message: `Duplicated template "${name} (Copy)"`,
												placement: "bottomLeft",
											});
											addTemplate({
												name: name + " (Copy)",
												fields: columns,
											}).then(() => {
												Notification.success({
													message: `Duplicated template "${name} (Copy)"`,
													placement: "bottomLeft",
												});
											});
										}
									},
								}}
							>
								<GenemodIcon name="meatballs" />
							</DropdownV2>
						</span>
					</>
				) : (
					<div
						className={styles.radioContainer}
						onClick={(e) => e.stopPropagation()}
					>
						<RadioButtonV2
							name={id.toString()}
							value={id.toString()}
							checked={!!checked}
							className={styles.radio}
							radioLabel
							onChange={(val) => {
								onRadioChange?.(Number(val));
							}}
						/>
						{checked !== undefined && (
							<Typography style={{ width: 60 }}>
								{checked ? "Selected" : "Select"}
							</Typography>
						)}
					</div>
				)}
			</div>
			<div
				className={classNames(styles.list, {
					[styles.listOpen]: !collapsed,
				})}
			>
				<DndContext
					sensors={sensors}
					onDragEnd={(e) => {
						const { active, over } = e;
						if (!over) return;
						if (active.id !== over.id) {
							const oldIndex = columns.indexOf(
								active.id as string
							);
							const newIndex = columns.indexOf(over.id as string);
							const newColumns = arrayMove(
								columns,
								oldIndex,
								newIndex
							);
							setColumns(newColumns);
							patchTemplate({
								id,
								columns_order: newColumns.join(","),
							});
						}
					}}
				>
					<SortableContext
						items={columns}
						strategy={verticalListSortingStrategy}
					>
						{name === "Default" && predefined
							? columns.map((col, i) => {
									return (
										<MaterialTemplateCollapsableRow
											key={i}
											schema={{
												uuid: i.toString(),
												label: DEFAULT_MATERIAL_TEMPALTE_LAYOUT[
													col as keyof typeof DEFAULT_MATERIAL_TEMPALTE_LAYOUT
												],
												type: 1,
											}}
											premade={predefined}
											templateId={id}
											schemaObj={schemaObj}
											col={col}
											viewOnly={showOnly}
											last={i === columns.length - 1}
										/>
									);
							  })
							: columns.map((col, i) => {
									if (schemaObj[col]) {
										return (
											<MaterialTemplateCollapsableRow
												key={i}
												schema={schemaObj[col]}
												premade={predefined}
												templateId={id}
												schemaObj={schemaObj}
												col={col}
												viewOnly={showOnly}
												last={i === columns.length - 1}
											/>
										);
									}
									return <></>;
							  })}
					</SortableContext>
				</DndContext>
			</div>
			<DeleteModal
				visible={showDeleteModal}
				onCancel={() => {
					setShowDeleteModal(false);
				}}
				onConfirm={() => {
					deleteTemplate(id);
				}}
				title="Delete material template"
				dangerButton
				okText="Delete material template"
				hideCancelButton={false}
				showUndo={false}
				message={
					<Typography>
						You are about to delete the <b>{name}</b> template.
						Deleting this template will also remove all associated
						date in the material tables that are currently using it.{" "}
						<b>This action cannot be undone.</b>
					</Typography>
				}
			/>
		</div>
	);
};

export default MaterialTemplateCollapsable;
