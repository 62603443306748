import { useTheme } from "@common/components/Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "@common/components/Theme/context/ThemeTypes";
import { Colors } from "@common/styles/Colors";
import {
	ButtonV2,
	EmptyState,
	Filter,
	GradientContainer,
	SidePanel,
	TabList,
	Typography,
	UserAvatar,
} from "@components";
import { Skeleton } from "antdv4";
import React, { createContext, useContext, useEffect, useState } from "react";
import OrderingCard from "../OrderingCard";
import EMPTY_STATE_DARK from "./assets/order-panel-empty-dark.png";
import EMPTY_STATE_LIGHT from "./assets/order-panel-empty-light.png";
import styles from "./index.module.scss";
import ORDER_DEMO_DARK from "./order-demo-dark.png";
import ORDER_DEMO_LIGHT from "./order-demo-light.png";

import { PageNumbers } from "@common/components";
import { TypedSort } from "@common/components/Sort/Sort";
import { useNavigateToCreateOrderPage } from "@common/helpers/Hooks/useNavigateToCreateOrderPage";
import { mapToKeyValue } from "@common/helpers/TypeHelpers";
import { UserId } from "@common/types";
import {
	FREEZER_ORDER_STATUSES,
	FreezerOrderStatusId,
} from "@common/types/FreezerOrder";
import RestrictionGradientLayout from "@containers/Freezer/components/RestrictionGradientLayout/RestrictionGradientLayout";
import { emphasizeText } from "@helpers/Formatters";
import { useOrdersSearchQuery } from "@helpers/Hooks/OrdersHooks";
import {
	useCurrentPlanNameHook,
	useFeatureRestrictionHook,
} from "@helpers/Hooks/featureRestrictionHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useFreezerSettingsQuery } from "@redux/freezer/FreezerApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export enum ORDER_MODE {
	"CREATE",
	"VIEW",
	"EDIT",
	"REORDER",
	"DETAILS",
}
type OrderingTab = "active" | "complete";

type OrderingContextProps = {
	viewedOrderList: number[];
	setViewedOrderList: (ids: number[]) => void;
};

const OrderingContext = createContext<OrderingContextProps>({
	viewedOrderList: [],
	setViewedOrderList: () => {},
});

export default function OrderingWidget(): JSX.Element {
	const [theme] = useTheme();
	const { isOrderingWidgetVisible, closeOrderingWidget, orderingWidgetData } =
		useCommonPanelState("orderingWidget");
	const orderMode = orderingWidgetData.mode;
	const [tab, setTab] = useState<OrderingTab>("active");
	const [viewedOrderList, setViewedOrderList] = useState<number[]>([]);
	// True if the order management feature is unavailable to the user
	const { is_limit_reached: restricted } =
		useFeatureRestrictionHook("order_management");
	const navigateToCreateOrderPage = useNavigateToCreateOrderPage();
	const { planName } = useCurrentPlanNameHook();
	const isFreePlan = planName === "free";

	const showIncompleteTab = useShowIncompleteTab(isOrderingWidgetVisible);

	useEffect(() => {
		if (!isOrderingWidgetVisible) {
			setTab("active");
			setViewedOrderList([]);
		}
	}, [isOrderingWidgetVisible]);

	useEffect(() => {
		if (
			orderingWidgetData.mode === ORDER_MODE.VIEW &&
			orderingWidgetData.tab
		) {
			setTab(orderingWidgetData.tab);
		}
	}, [orderingWidgetData]);

	const DemoComp = () => {
		return (
			<div className={styles.demo}>
				<GradientContainer
					color={Colors["layer-01"]}
					className={styles.demoImage}
					topHeight={0}
					bottomHeight={0}
				>
					<img
						src={
							theme === THEME_PREFERENCE.light
								? ORDER_DEMO_LIGHT
								: ORDER_DEMO_DARK
						}
					/>
				</GradientContainer>
				<div
					style={{
						position: "absolute",
						bottom: "0px",
						background: "var(--current-layer)",
						right: "24px",
					}}
				>
					<RestrictionGradientLayout
						title={emphasizeText`Upgrade to unlock your ${"order management"} experience`(
							"accent-subtle"
						)}
					/>
				</div>
			</div>
		);
	};

	const TABS = [
		{
			key: "active",
			tabtitle: "Active",
			tabcontent: restricted ? <DemoComp /> : <OrderList />,
		},
		{
			key: "complete",
			tabtitle: "Complete",
			tabcontent: restricted ? (
				<DemoComp />
			) : (
				<OrderList type="complete" />
			),
		},
	];

	if (showIncompleteTab) {
		TABS.push({
			key: "incomplete",
			tabtitle: "Incomplete",
			tabcontent: restricted ? (
				<DemoComp />
			) : (
				<OrderList type="incomplete" />
			),
		});
	}

	return (
		<OrderingContext.Provider
			value={{
				viewedOrderList,
				setViewedOrderList,
			}}
		>
			<SidePanel
				isVisible={
					isOrderingWidgetVisible && orderMode === ORDER_MODE.VIEW
				}
				onClose={() => closeOrderingWidget()}
				header={
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							variant="title"
							color="text-secondary-v2"
							semibold
						>
							Orders
						</Typography>
						{!isFreePlan && (
							<ButtonV2
								type="primary"
								onClick={() => {
									navigateToCreateOrderPage();
									closeOrderingWidget();
								}}
							>
								New order
							</ButtonV2>
						)}
					</div>
				}
				showIcon={false}
			>
				<TabList
					largeSize
					tabListItems={TABS}
					activeKey={tab}
					onChange={setTab}
				/>
			</SidePanel>
		</OrderingContext.Provider>
	);
}
const ORDER_LIST_SORT = mapToKeyValue(
	[
		{ label: "Newest", value: "-submitted_at" },
		{ label: "Oldest", value: "submitted_at" },
		{ label: "Item name (a-z)", value: "name" },
	] as const,
	"label"
);
type SortType = keyof typeof ORDER_LIST_SORT;
const DEFAULT_SORT = ORDER_LIST_SORT.Newest.label;

type OrderListProps = {
	/** Type of orders to display */
	type?: "active" | "complete" | "incomplete";
};

const PAGE_SIZE = 25;

function OrderList({ type = "active" }: OrderListProps): JSX.Element {
	const { data: freezerSettings } = useFreezerSettingsQuery();

	const { teamMembers } = useCurrentTeamMembers();

	const { viewedOrderList, setViewedOrderList } = useContext(OrderingContext);
	const orderLastViewedAt = freezerSettings?.order_last_viewed_at || "";
	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState<SortType>(DEFAULT_SORT);

	const defaultActiveFilters = [
		FREEZER_ORDER_STATUSES.Requested.id,
		FREEZER_ORDER_STATUSES.Approved.id,
		FREEZER_ORDER_STATUSES.Ordered.id,
	];

	const allowedFilters = {
		active: [],
		complete: [FREEZER_ORDER_STATUSES.Received.id],
		incomplete: [FREEZER_ORDER_STATUSES.Denied.id],
	}[type];

	const [statuses, setStatuses] =
		useState<FreezerOrderStatusId[]>(allowedFilters);
	const [requestedBy, setRequestedBy] = useState<UserId[]>([]);
	const [expandedOrder, setExpandedOrder] = useState<number | null>(null);

	const navigateToCreateOrderPage = useNavigateToCreateOrderPage();
	const { isOrderingWidgetVisible, closeOrderingWidget } =
		useCommonPanelState("orderingWidget");

	const { data: orders, isLoading } = useOrdersSearchQuery(
		isOrderingWidgetVisible
			? {
					page,
					page_size: PAGE_SIZE,
					ordering: ORDER_LIST_SORT[sortBy].value,
					status__in: statuses.length
						? statuses
						: defaultActiveFilters,
					include_filter_options: true,
					submitted_by__in: requestedBy,
					is_archived: false,
			  }
			: skipToken
	);

	const getUpdatedFilters =
		<T,>(options: T[]) =>
		(option: T | "clear") => {
			if (option === "clear") {
				return [];
			}
			let temp = options.slice();
			if (temp.includes(option)) {
				temp = temp.filter((x) => x !== option);
			} else {
				temp = [...temp, option];
			}
			return temp;
		};

	const renderCardPlaceholders = () => {
		const placeholders = [];
		for (let i = 0; i < 3; i++) {
			placeholders.push(
				<div
					className={styles.cardPlaceholder}
					key={`cardplaceholder-${i}`}
				>
					<Skeleton
						avatar={{ size: "small" }}
						paragraph={{ rows: 2 }}
						round={true}
					/>
				</div>
			);
		}
		return placeholders;
	};

	const hasNoOrders = !orders?.results.length;

	if (hasNoOrders) {
		return (
			<div className={styles.orderList}>
				<EmptyState
					img={{
						light: EMPTY_STATE_LIGHT,
						dark: EMPTY_STATE_DARK,
					}}
					style={{
						marginTop: 32,
					}}
					title={
						type === "complete"
							? "No complete orders found"
							: "No active orders found"
					}
					description={
						type === "active"
							? "Reorder items that are running low."
							: undefined
					}
					learnMore={
						type === "active"
							? "https://intercom.help/genemod/en/articles/6370023-managing-orders-requested-and-completed"
							: undefined
					}
				>
					{type === "active" && (
						<ButtonV2
							style={{
								width: 166,
							}}
							type="primary"
							onClick={() => {
								navigateToCreateOrderPage();
								closeOrderingWidget();
							}}
						>
							Create new order
						</ButtonV2>
					)}
				</EmptyState>
			</div>
		);
	}

	const requestedByFilterOptions = (
		orders?.filter_options?.submitted_by || []
	).reduce((s, v) => {
		const teamMember = teamMembers?.find((tm) => tm.id === v.value);
		if (!teamMember) return s;
		return {
			...s,
			[v.value]: (
				<UserAvatar user={teamMember.user} size={18} displayName />
			),
		};
	}, {} as Record<number, React.ReactNode>);

	return (
		<div className={styles.orderList}>
			{orders && (
				<div className={styles.filters} id="orderingwidgetfilters">
					<div className={styles.left}>
						<Filter
							label="Requester"
							filterOptions={requestedByFilterOptions}
							selectedOptions={requestedBy}
							onChange={(v) =>
								setRequestedBy(
									getUpdatedFilters<UserId>(requestedBy)(
										v as UserId | "clear"
									)
								)
							}
							dropdownAlign={{
								points: ["tr", "br"],
								offset: [0, 4],
							}}
							bordered
						/>
						{type === "active" && (
							<Filter
								label="Status"
								filterOptions={{
									0: "Requested",
									1: "Approved",
									2: "Ordered",
									Requested: 0,
									Approved: 1,
									Ordered: 2,
								}}
								selectedOptions={statuses}
								onChange={(v) =>
									setStatuses(
										getUpdatedFilters<FreezerOrderStatusId>(
											statuses
										)(v as FreezerOrderStatusId | "clear")
									)
								}
								dropdownAlign={{
									points: ["tr", "br"],
									offset: [0, 4],
								}}
								bordered
							/>
						)}
					</div>
					<TypedSort
						sortList={Object.keys(ORDER_LIST_SORT)}
						value={sortBy}
						onChange={(v) => setSortBy(v as SortType)}
					/>
				</div>
			)}
			<div className={styles.cards}>
				{orders &&
					orders.results.map((order) => (
						<OrderingCard
							orderInfo={order}
							key={order.id + order.updated_at}
							isUpdated={
								orderLastViewedAt <= order.updated_at &&
								!viewedOrderList.includes(order.id)
							}
							isExpanded={expandedOrder === order.id}
							onClick={() => {
								if (expandedOrder === order.id) {
									setExpandedOrder(null);
								} else {
									setExpandedOrder(order.id);
								}
								if (!viewedOrderList.includes(order.id)) {
									// add orderId into viewedOrderList
									setViewedOrderList([
										...viewedOrderList,
										order.id,
									]);
								}
							}}
						/>
					))}
				{orders && orders.results.length === 0 && (
					<Typography>No active orders found</Typography>
				)}
				{isLoading && renderCardPlaceholders()}
				{(orders?.total_page_count || 0) > 1 && (
					<div className={styles.pageNumbersWrapper}>
						<PageNumbers
							totalPages={orders?.total_page_count}
							page={page}
							onPageChange={(page) => setPage(page)}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

const useShowIncompleteTab = (isOrderingWidgetVisible: boolean) => {
	const { data } = useOrdersSearchQuery(
		!isOrderingWidgetVisible
			? skipToken
			: {
					page: 1,
					page_size: 5,
					include_filter_options: true,
					is_archived: false,
			  }
	);

	const showIncompleteTab =
		(data?.filter_options?.status?.filter(
			({ name, count }) =>
				(name as unknown as FreezerOrderStatusId) ===
					FREEZER_ORDER_STATUSES.Denied.id && count > 0
		)?.length || -1) > 0;
	return showIncompleteTab;
};
