import { Box, ItemGroup } from "@common/types";
import {
	DropDown,
	DropdownV2,
	GenemodIcon,
	Notification,
	Typography,
} from "@components";
import { useExportState } from "@containers/Freezer/hooks";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import AddBoxModal from "./AddBoxModal";
import "./RackView.scss";

import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import { FREEZER_PATHS } from "@containers/Freezer";
import { wasCreatedOnTheLastFiveSeconds } from "@helpers/UXHelpers";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useBoxRelocateMutation } from "@redux/inventory/Box";
import { useOrganizationRouter } from "@root/AppRouter";
import { useHistory } from "react-router-dom";
import styles from "./BoxTableCell.module.scss";
import { useRepository } from "../Repository/RepositoryContext";

const MOVING_CART_KEY = "moving_cart";
const ItemTypes = {
	BOX: "box",
};

type BoxTableCellProps = {
	/** box in this cell */
	box: ItemGroup | Box;
	/** row of this cell (Box only)*/
	row?: number;
	/** col of this cell (Box only)*/
	col?: number;
	/** whether to show rearrangeBoxModal or not */
	showRearrangeBoxModal: boolean;
	/** whether to show editRackModal or not */
	showEditRackModal: boolean;
	/** style of the cell (Box only) */
	cellStyle?: React.CSSProperties;
	/** True, the parent freezer is archived */
	viewOnly: boolean;
	/** callback function to set cartBoxId for MovingCart */
	setMovingCartBoxId: (boxId: number) => void;
	/** callback function to add new box in this cell position */
	handleAddBox: (box: Partial<Box>, extra: () => void) => void;
	/** onClick behavior when clicked this cell */
	onClick: () => void;
	onChangeLocation?: (boxId: number, row: number, col: number) => void;
};

/** Cell Component in the BoxTable of Rack or Category */
export default function BoxTableCell(props: BoxTableCellProps): JSX.Element {
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const { openCustomizeBoxModal } = useCommonModalState("customizeBoxModal");
	const [isRenaming, setRenaming] = useState(false);
	const [isAdding, setAdding] = useState(false);
	const [dropdownVisible, setDropdown] = useState(false);
	const { handleExport, exportModal } = useExportState();
	const [emptyCellHovered, setEmptyCellHovered] = useState(false);
	const { appendBaseUrl } = useOrganizationRouter();

	const [relocateBox] = useBoxRelocateMutation();

	const { openDeleteBoxModal, isDeleteBoxModalVisible } =
		useCommonModalState("deleteBoxModal");
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("boxSettings");
	const history = useHistory();
	const { setSelectedLayer, setSelectedItems, setIsArchiveModalVisible } =
		useRepository();

	useEffect(() => {
		if (!props.box && emptyCellHovered) setEmptyCellHovered(false);
	}, [props.box]);

	const handleDropdownSelection = (e: ClickParam) => {
		const event = e.domEvent;
		event.cancelBubble = true;
		event.stopPropagation();
		setDropdown(false);

		const key = e.key;
		if (key === "rename") {
			setRenaming(true);
		} else if (key === "delete") {
			openDeleteBoxModal({ id: props.box.id });
		} else if (key === "setting") {
			openPanel({ id: props.box.id });
		} else if (key === MOVING_CART_KEY) {
			props.setMovingCartBoxId(props.box?.id);
		} else if (key === "share") {
			openShareLinkModal({
				additionalPath: `boxes/${props.box?.id}`,
			});
		} else if (key === "export") {
			handleExport("boxes", props.box.id, props.box.name);
		} else if (key === "customize") {
			openCustomizeBoxModal({
				boxId: props.box.id,
			});
		} else if (key === "archive") {
			setSelectedLayer("BOX");
			setSelectedItems([props.box]);
			setIsArchiveModalVisible(true);
		} else if (key === "import") {
			history.push({
				pathname: appendBaseUrl(
					`${FREEZER_PATHS.BASE}/freezers/ ${props.box.location.freezer}/boxes/${props.box.id}`
				),
				state: {
					isImporting: true,
				},
			});
		}
	};

	const handleAddBox = (box: Partial<Box>) => {
		// Set the location
		if (
			props.row !== undefined &&
			props.col !== undefined &&
			box.location?.rack_location
		) {
			box.location.rack_location.row = props.row;
			box.location.rack_location.column = props.col;
		}
		props.handleAddBox(box, () => setAdding(false));
	};

	const handleCellClick = () => {
		if (
			!dropdownVisible &&
			!isAdding &&
			!isDeleteBoxModalVisible &&
			!isRenaming &&
			!exportModal &&
			props.box
		) {
			props.onClick();
		}
	};

	const dropdownMenu = (
		<Menu onClick={handleDropdownSelection}>
			{!props.viewOnly && (
				<DropdownMenuItem
					key={MOVING_CART_KEY}
					label="Move to"
					icon="open-box"
					data-cy="box-menu-move"
				/>
			)}
			{!props.viewOnly && (
				<DropdownMenuItem
					key="customize"
					label="Customize box"
					icon="edit"
					data-cy="box-menu-customize"
				/>
			)}
			{!props.viewOnly && <DropdownMenuDivider />}
			{!props.viewOnly && (
				<DropdownMenuItem
					key="import"
					label="Import items"
					icon="download"
					data-cy="box-menu-import"
				/>
			)}
			<DropdownMenuItem
				key="export"
				label="Export"
				icon="export"
				data-cy="box-menu-export"
			/>
			{
				// ---- UNCOMMENT WHEN AVAILABLE -----
				// <Menu.Item key="archive">
				// 	<div className={styles.menuItem}>
				// 		<GenemodIcon name="printer" />
				// 		<Typography variant="label" color="text-secondary">
				// 			Print barcode
				// 		</Typography>
				// 	</div>
				// </Menu.Item>
			}
			<DropdownMenuDivider />
			<DropdownMenuItem
				key="setting"
				label="Settings"
				icon="settings"
				data-cy="box-menu-settings"
			/>
			<DropdownMenuItem
				key="share"
				label="Share"
				icon="share"
				data-cy="box-menu-share"
			/>
			<DropdownMenuDivider />
			{!props.viewOnly && (
				<DropdownMenuItem
					key="archive"
					label="Archive"
					icon="archive"
					data-cy="box-menu-archive"
				/>
				// <DropdownMenuItem
				// 	key="delete"
				// 	label="Delete"
				// 	icon="trash"
				// 	color="dust-red"
				// 	data-cy="box-menu-delete"
				// />
			)}
		</Menu>
	);

	// DRAG AND DROP
	const ref = useRef<HTMLDivElement>(null);
	const boxToDrag = props.box;
	const [{ isDragging }, drag] = useDrag({
		item: {
			type: ItemTypes.BOX,
			id: boxToDrag ? boxToDrag.id : 0,
			location_col: boxToDrag
				? boxToDrag.location.rack_location?.column
				: 0,
			location_row: boxToDrag ? boxToDrag.location.rack_location?.row : 0,
			parent_rack: boxToDrag ? boxToDrag.location.freezer : 0,
			box: boxToDrag,
		},
		begin() {
			return {
				type: ItemTypes.BOX,
				id: props.box.id,
				location_col: props.box.location.rack_location?.column || -1,
				location_row: props.box.location.rack_location?.row || -1,
				parent_rack: boxToDrag.location.rack_location?.rack || -1,
				box: boxToDrag,
			};
		},
		canDrag() {
			if (!!props.onChangeLocation && !props.box?.is_archived)
				return false;
			return props.showRearrangeBoxModal && !!props.box;
		},
		collect(monitor) {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	});

	const [, drop] = useDrop({
		accept: ItemTypes.BOX,

		drop(item) {
			const { box: _box } = item as any;
			const box = _box as Box;
			if (props.onChangeLocation) {
				props.onChangeLocation(
					box.id,
					props.row as number,
					props.col as number
				);
			} else {
				relocateBox({
					boxId: box.id,
					first_location: {
						row: box.location?.rack_location?.row || 0,
						column: box.location?.rack_location?.column || 0,
					},
					second_location: {
						row: props.row as number,
						column: props.col as number,
					},
					rackId: box.location?.rack_location?.rack as number,
				})
					.unwrap()
					.catch(() =>
						Notification.warning({
							message:
								"Failed to rearrange racks. Try again or contact us if it continues.",
						})
					);
			}
		},
	});

	drag(drop(ref));

	const isNewCardStyle = wasCreatedOnTheLastFiveSeconds(props.box)
		? {
				border: "1px solid var(--borders-lines-border-active, var(--border-active))",
				boxShadow: "0px 0px 8px 4px rgba(5, 29, 133, 0.20)",
				animation: "fadeOutBorderAndShadow .4s forwards",
				animationDelay: "3s",
		  }
		: {};

	return (
		<div
			ref={ref}
			className={cn(styles.boxCell, {
				[styles.emptyCell]: !props.box,
				[styles.shadow]: props.box,
				[styles.noHover]:
					props.showEditRackModal || (props.viewOnly && !props.box),
				[styles.disabled]:
					!!props.onChangeLocation && !props.box?.is_archived,
				[styles.shake]:
					props.showRearrangeBoxModal && props.box?.is_archived,
			})}
			style={{
				...props.cellStyle,
				opacity: isDragging ? 0.5 : 1,
				...isNewCardStyle,
			}}
			onClick={() => {
				if (
					props.showEditRackModal === false &&
					props.showRearrangeBoxModal === false
				)
					handleCellClick();
			}}
			data-box-id={props.box?.id}
			data-cy={`box-r${props.row}-c${props.col}`}
		>
			{props.box ? (
				<>
					{!props.box?.is_archived ? (
						<DropdownV2
							overlay={dropdownMenu}
							visible={dropdownVisible}
							onVisibleChange={(v) => {
								if (
									props.showEditRackModal === false &&
									props.showRearrangeBoxModal === false
								)
									setDropdown(v);
							}}
							placement="bottomLeft"
							getPopupContainer={(trigger) => {
								const nOptions =
									dropdownMenu.props?.children?.length || 1;
								const dropdownHeight = 48 * (nOptions + 1);
								const boxContainerEl =
									trigger.parentElement?.parentElement;
								const rackContainerEl =
									boxContainerEl?.parentElement;
								if (!boxContainerEl || !rackContainerEl)
									return document.body;
								return rackContainerEl.clientHeight >
									dropdownHeight
									? boxContainerEl
									: rackContainerEl;
							}}
						>
							<GenemodIcon
								className={styles.options}
								onClick={(e) => {
									e.stopPropagation();
								}}
								name="meatballs"
								size="large"
								dataCy={`box-r${props.row}-c${props.col}-dropdown`}
							/>
						</DropdownV2>
					) : null}
					<div className={styles.line}></div>
					<div className={styles.content}>
						<Typography
							variant="label"
							ellipsis
							className={styles.cellName}
						>
							{props.box.name}
						</Typography>
					</div>
				</>
			) : !props.viewOnly ? (
				<div
					className={cn(styles.emptyCellContainer, {
						"empty-cell-hovered": emptyCellHovered,
					})}
					onDragOver={() => setEmptyCellHovered(true)}
					onDragLeave={() => setEmptyCellHovered(false)}
					onClick={() => {
						if (
							props.showEditRackModal === false &&
							props.showRearrangeBoxModal === false
						)
							setAdding(true);
					}}
				>
					<GenemodIcon name="plus-circle" size="large" />
				</div>
			) : (
				<div
					className={cn(styles.emptyCellContainer)}
					style={{ cursor: "default" }}
				/>
			)}
			<AddBoxModal
				visible={isAdding}
				onCancel={() => setAdding(false)}
				onConfirm={handleAddBox}
			/>
			{exportModal}
		</div>
	);
}
